export const TAB_NAME = [
  "dashboard##visitor_behavior@@overall",
  "messages##from_web@@quotation",
  "messages##from_web@@inquiry",
  // "marketing##all_potential_client@@leads",
  "marketing##all_potential_client@@subcription",
  "web_content##manage_web_content@@products",
  "web_content##manage_web_content@@catalogues",
  "web_content##manage_web_content@@careers",
];
