import React, { useState } from "react";
import Sidebar from "page/Dashboard/Sidebar";
import Content from "page/Dashboard/Content";
import Chatbar from "page/Dashboard/Chatbar";

import { motion, AnimatePresence } from "framer-motion";

import { TAB_NAME } from "./Dashboard/Info";
import { useFireUser } from "mFirebase/useFireUser";
import useDebounce from "component/hook/useDebounce";
import { useImages } from "component/hook/useImages";
import LoginPage from "./LoginPage";

export default function Dashboard(props) {
  const images = useImages();
  const auth = useFireUser();
  const [showSidebar, setShowSidebar] = useState(true);
  const [currentTab, setCurrentTab] = useState(TAB_NAME[0]);
  // if (!auth) {
  //   return <div className="w-screen h-screen bg-black"></div>;
  // }
  // if (!auth.user) {
  //   return <div className="a">go login</div>;
  // }
  return (
    <React.Fragment key="dashboard">
      <AnimatePresence exitBeforeEnter>
        {!auth?.isReady && (
          <motion.div
            key="splash"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1, duration: 0.3 }}
            className="fixed top-0 left-0 w-screen h-screen z-50 bg-[#0f172a] flex-center"
          >
            <img src={images["logo.png"]} />
          </motion.div>
        )}

        {!auth?.user && auth?.isReady && <LoginPage />}

        {auth?.user && (
          <motion.div
            key="_dashboard"
            className="min-w-screen h-full flex items-stretch max-h-screen"
          >
            <Sidebar {...{ showSidebar, currentTab, setCurrentTab }} />
            <Content {...{ setShowSidebar, currentTab }} />
            <Chatbar />
          </motion.div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
}
