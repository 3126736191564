import React, { useState, useEffect } from "react";
import useLocalStorage from "component/hook/useLocalStorage";
import { Link } from "react-router-dom";
import { GenID, UpdateDB, UseDB } from "mFirebase/useAdminEvent";
import _, { set } from "lodash";

const default_contact_info = {
  contact_name: "",
  contact_method: "",
};
export default function CheckOutPage() {
  const db_category = UseDB("/product/_meta/category");
  const [productImage, setproductImage] = useState({});

  const [contact_info, setcontact_info] = useState({
    contact_name: "",
    contact_method: "",
  });

  useEffect(() => {
    const d = {};
    _.entries(db_category).map(([category_name, category_obj]) => {
      category_obj.sub_category.map((x) => {
        if (!d[category_name]) d[category_name] = {};
        d[category_name][x.name] = x.url;
      });
    });
    setproductImage(d);
  }, [db_category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [store_quotation_list, setstore_quotation_list] = useLocalStorage(
    "quotation_list",
    []
  );
  return (
    <React.Fragment>
      <div className="flex-1 container mt-[6em] mb-[6em] lg:mb-[10em] mx-auto flex items-center md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12">
        <div
          className="bg-white w-full p-6 rounded-[1em]"
          style={{
            boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <div className="uppercase font-semibold text-[#000000cc] my-6 pb-4 flex justify-between">
            <div className="text-base  xl:text-[1.7em] ">
              Request quotations for{" "}
            </div>
          </div>
          <div className="flex flex-col gap-6 mt-4">
            {store_quotation_list.length === 0 && (
              <Link to={"/shop"}>
                <div className="w-full h-[20rem] flex-center mt-4 border rounded-[0.5rem] text-gray-300 cursor-pointer hover:text-gray-500">
                  Cart is empty, press here to visit shop now
                </div>
              </Link>
            )}
            {store_quotation_list.map((x, i) => {
              return (
                <div
                  key={i}
                  className="flex gap-4 border-b border-[#00000055] pb-4 flex-none"
                >
                  <img
                    src={
                      productImage?.[x.detail.category]?.[x.detail.sub_category]
                    }
                    alt=""
                    className="w-20 h-20 xl:w-28 xl:h-28 object-contain border rounded-[0.5rem] overflow-hidden flex-none"
                  />
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col gap-4  xl:flex-row border-b pb-4 items-stretch xl:items-center">
                      <div className="font-semibold flex-1">
                        <div className="a">
                          {x.detail.name}{" "}
                          {x?.variation && (
                            <span className="font-light uppercase">
                              - {x?.variation}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex self-start xl:self-auto">
                          <div
                            onClick={() => {
                              setstore_quotation_list((p) => {
                                const newState = _.cloneDeep(p);
                                newState[i].quantity = _.clamp(
                                  p[i].quantity - 1,
                                  1,
                                  999
                                );
                                return newState;
                              });
                            }}
                            className="border rounded-full h-[2em] w-[2em] flex-center cursor-pointer select-none"
                          >
                            -
                          </div>
                          <div className="h-[2em] w-[2em] flex-center font-bold">
                            {x.quantity}
                          </div>
                          <div
                            onClick={() => {
                              setstore_quotation_list((p) => {
                                const newState = _.cloneDeep(p);
                                newState[i].quantity = _.clamp(
                                  p[i].quantity + 1,
                                  1,
                                  999
                                );
                                return newState;
                              });
                            }}
                            className="border rounded-full h-[2em] w-[2em] flex-center cursor-pointer select-none"
                          >
                            +
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setstore_quotation_list((p) => {
                              const newState = _.cloneDeep(p).filter(
                                (n) => n.product_id !== x.product_id
                              );

                              return newState;
                            });
                          }}
                          className=" xl:hidden flex-none ml-4 self-end cursor-pointer"
                        >
                          {svg_delete}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col xl:flex-row flex-1">
                      <div className="pt-2 flex-1 font-light text-[#00000055] leading-4 xl:leading-5">
                        {x.detail.category} / {x.detail.sub_category}
                      </div>
                      <div
                        onClick={() => {
                          setstore_quotation_list((p) => {
                            const newState = _.cloneDeep(p).filter(
                              (n) => n.product_id !== x.product_id
                            );

                            return newState;
                          });
                        }}
                        className="hidden xl:block flex-none ml-4 self-end cursor-pointer mb-1 mr-1"
                      >
                        {svg_delete}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {store_quotation_list.length > 0 && (
            <div className="flex mt-12">
              <div className="flex-1 font-light text-[#00000055]">
                Total Items In List
              </div>
              <div className="a">
                {store_quotation_list.reduce((p, c) => p + c.quantity, 0)} items
              </div>
            </div>
          )}

          <div className="mt-24 flex items-center gap-4">
            <div className="capitalize text-[#00000088] font-medium">
              Customer details
            </div>
            <div className="flex-1 bg-gray-300 h-px"></div>
          </div>

          <div className="mt-6 text-[#00000088] flex flex-col">
            <div className="flex flex-col gap-4  mt-6 ">
              <input
                value={contact_info.contact_name}
                onChange={(e) => {
                  setcontact_info((prev) => {
                    return { ...prev, contact_name: e.target.value };
                  });
                }}
                type="text"
                className="border px-[2em] h-[3.5em] rounded-[0.5em]"
                placeholder="company name / individual"
              />
              <input
                value={contact_info.contact_method}
                onChange={(e) => {
                  setcontact_info((prev) => {
                    return { ...prev, contact_method: e.target.value };
                  });
                }}
                type="text"
                className="border px-[2em] h-[3.5em] rounded-[0.5em]"
                placeholder="email address / phone number"
              />
            </div>
          </div>
          <div className="flex justify-end mt-12">
            <div className="flex-1"></div>
            <div
              onClick={() => {
                if (store_quotation_list.length < 1) {
                  return alert("Your quotation request list is empty.");
                }
                if (contact_info.contact_name === "") {
                  return alert(
                    "Please fill in your company name / your name as a record"
                  );
                }
                if (contact_info.contact_method === "") {
                  return alert(
                    "Please fill in your email address / phone number for us to reply to"
                  );
                }

                const pushID = GenID();
                const res = UpdateDB("quotation", {
                  [pushID]: {
                    ...contact_info,
                    list: [...store_quotation_list],
                  },
                });
                if (res.error) {
                  return alert(
                    "Something wrong happened, please try again or contact us."
                  );
                }
                setstore_quotation_list([]);
                setcontact_info(default_contact_info);
                return alert("Request submitted!");
              }}
              className=" cursor-pointer bg-blue-ci py-[1em] px-[4em] uppercase text-white text-[1em] font-semibold text-sm text-center rounded-[0.5rem] mb-[2rem]"
            >
              send request
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const svg_delete = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="20"
    width="20"
    fill="#ff0000"
  >
    <path d="M13.05 42q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z" />
  </svg>
);
