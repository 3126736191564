import { Link } from "react-router-dom";

import { useImages, useCareerImages } from "component/hook/useImages";
import React, { useEffect, useState } from "react";
import useWindowSize from "component/hook/useWindowSize";
import { UseCareer } from "mFirebase/useAdminEvent";
import { motion } from "framer-motion";
import { AddCareerApplication } from "mFirebase/useClientEvent";
import _ from "lodash";

export default function CareerPage() {
  const db_career = UseCareer();
  const images = useImages();
  const win = useWindowSize();
  const careerImages = useCareerImages();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [db_career]);

  return (
    <React.Fragment>
      <div className="min-h-screen -mt-[4.5em] flex flex-col">
        {/* ----- HEADER ----- */}
        <div className="relative h-[6em] flex mt-[7em] lg:mt-[8.5em]">
          <div className="relative bg-blue-ci w-[12em] sm:w-[20em] lg:w-[24em] 2xl:w-[30em] rounded-br-[3em] h-[4em] text-white font-bold flex items-center ">
            <div className="absolute right-full h-full bg-blue-ci w-[50vw]"></div>
          </div>
          <div className="flex-1 rounded-tl-[3em] overflow-hidden h-[4em] self-end text-white font-bold flex items-center">
            <img
              src={careerImages["banner.webp"]}
              className="w-full h-full object-cover object-center "
            />
          </div>
          <div className="absolute container flex items-center text-white   md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 left-1/2 -translate-x-1/2 h-[4em]">
            <div className="text-[1.4em] sm:text-[1.7em] h-full font-semibold flex items-center uppercase">
              Career
            </div>
          </div>
        </div>
        {/* _____ HEADER _____ */}

        {/* ----- JOB_LIST ----- */}
        <div className="flex-1 container mt-[6em] mb-[6em] lg:mb-[0em] mx-auto flex flex-col items-center md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 gap-12">
          {Object.entries(db_career?.position || {})
            .map(([career_id, obj]) => {
              return { ...obj, career_id };
            })
            .filter((x) => x.visible)
            .map((x) => {
              return (
                <JobItem
                  {...{
                    data: x,
                  }}
                />
              );
            })}
        </div>
        {/* _____ JOB_LIST _____ */}

        <div className="mt-[8em]"></div>
      </div>
    </React.Fragment>
  );
}

function JobItem({ data }) {
  const [showForm, setShowForm] = useState(false);
  const [submited, setsubmited] = useState(false);
  const [application_data, setApplication_data] = useState({
    career_id: data.career_id,
    career_info: _.omit(data, ["career_id"]),
    fullname: "",
    contact: "",
    description: "",
  });
  return (
    <div
      style={{
        boxShadow: "rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
      className="w-full bg-white p-[2em] pl-[1.5em] sm:pl-[2em] pt-[1.5em] rounded-[1em] flex flex-col sm:flex-row relative"
    >
      <div className="hidden sm:flex justify-center items-center w-[4em] h-[4em] -mt-[0.5em] flex-none rounded-full border-[#ddd] border-2">
        <span className="material-symbols-rounded text-[2em] text-blue-ci ">
          person
        </span>
      </div>
      <div className="flex-1 flex flex-col pl-[1em] sm:pl-[2em] text-blue-ci">
        <div className="uppercase flex items-center">
          <div className="text-[1.7em] font-semibold">{data.title}</div>
        </div>
        <div className="h-[2px] bg-[#333333] mr-[-2em] mt-[1.5em] mb-[2em] opacity-10"></div>

        <div className="flex flex-col font-extralight">
          <div className="a">Responsibility</div>
          <div className="flex flex-col pl-[1em] mt-[1em]">
            {data.responsibility.split("\n").map((x) => {
              return (
                <div key={x} className="a">
                  {x}
                </div>
              );
            })}
          </div>

          <div className="mt-[1.5em]">Requirements</div>
          <div className="flex flex-col pl-[1em] mt-[1em]">
            {data.requirement.split("\n").map((x) => {
              return (
                <div key={x} className="a">
                  {x}
                </div>
              );
            })}
          </div>
        </div>

        <div className="h-[2px] bg-[#333333] mr-[-2em] mt-[2em] mb-[2em] opacity-10"></div>

        <motion.div
          initial={{ maxHeight: "0vh" }}
          animate={showForm ? { maxHeight: "400px" } : { maxHeight: "0px" }}
          transition={{ duration: 0.8, type: "tween" }}
          className="overflow-hidden"
        >
          {!submited && (
            <div className="flex flex-col gap-4 mb-4">
              <Input
                value={application_data.fullname}
                onChange={(e) => {
                  setApplication_data((prev) => {
                    const newState = { ...prev, fullname: e.target.value };
                    return newState;
                  });
                }}
                {...{ text: "Full Name" }}
              />
              <Input
                value={application_data.contact}
                onChange={(e) => {
                  setApplication_data((prev) => {
                    const newState = { ...prev, contact: e.target.value };
                    return newState;
                  });
                }}
                {...{ text: "Contact" }}
              />
              <InputTextArea
                value={application_data.description}
                onChange={(e) => {
                  setApplication_data((prev) => {
                    const newState = { ...prev, description: e.target.value };
                    return newState;
                  });
                }}
                {...{ text: "Tell us more" }}
              />
            </div>
          )}

          {submited && (
            <div className="flex items-center flex-col gap-4  my-[4em]">
              <div className="text-xl font-semibold">
                Thank you for joining us
              </div>
              <div className="font-light">
                We will review your application very soon.
              </div>
            </div>
          )}
        </motion.div>

        {!submited && (
          <div className="flex justify-end gap-4">
            <motion.div
              onClick={() => {
                setShowForm(!showForm);
              }}
              initial={{ opacity: 0 }}
              animate={showForm ? { opacity: 1 } : { opacity: 0 }}
              transition={{ duration: 0.8, type: "tween" }}
              className="flex-1 sm:right-[2em] hover:bg-gray-100 text-gray-600  hover:text-gray-600 rounded-[0.5em] h-[3.5em] px-[3em] flex-center cursor-pointer border-gray-400 border-[2px]"
            >
              Cancel
            </motion.div>

            <div
              onClick={async () => {
                if (submited) return;
                if (!showForm) {
                  return setShowForm(true);
                }
                // sanitize
                const res = await AddCareerApplication(application_data);
                if (res.error) {
                  return;
                }
                setsubmited(true);
              }}
              className="flex-1  text-center sm:right-[2em] hover:bg-blue-ci text-blue-ci  hover:text-white rounded-[0.5em] h-[3.5em] px-[3em] flex-center cursor-pointer border-blue-ci border-[2px]"
            >
              {showForm ? "Submit" : "Apply Position"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
function Input({
  text,
  type = "text",
  required,
  value,
  onChange,
  verify,
  changes,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          disabled={disabled}
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] bg-transparent appearance-none outline-none caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
function InputTextArea({
  text,
  required,
  value,
  changes,
  onChange,
  verify,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);

  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <textarea
          disabled={disabled}
          cols={4}
          value={value}
          onChange={onChange}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-transparent caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
