import { initializeApp, getApps } from "@firebase/app";
import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
let storedb;
let rtdb;

const useFirebaseAppImpl = () => {
  const [app, setApp] = useState(null);
  const firebaseConfig = {
    apiKey: "AIzaSyA5iO64gKcNG1E_fXOJipqDibQCn_PQpr8",

    authDomain: "shengyi-web.firebaseapp.com",

    databaseURL:
      "https://shengyi-web-default-rtdb.asia-southeast1.firebasedatabase.app",

    projectId: "shengyi-web",

    storageBucket: "shengyi-web.appspot.com",

    messagingSenderId: "522535778394",

    appId: "1:522535778394:web:572cf3d3975d11ce590063",

    measurementId: "G-VE9T9GFKRG",
  };
  useEffect(() => {
    if (getApps().length > 0) return;
    console.log("FIREBASE @ initializeApp");
    setApp(initializeApp(firebaseConfig));
  }, []);

  useEffect(() => {
    storedb = getFirestore();
    rtdb = getDatabase();
    analytics = getAnalytics();
  }, [app]);

  return app;
};
export const useFirebaseApp = singletonHook(null, useFirebaseAppImpl);
export { storedb, rtdb };

export let analytics = null;
