import { DelProduct, UseProduct } from "mFirebase/useAdminEvent";

import React, { useState, useEffect } from "react";
const _ = require("lodash");
export default function ProductDetail({
  sel_product,
  oc_close,
  setShowCategoryManager,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const db_product = UseProduct();
  const [product_data, setProduct_data] = useState({
    name: "",
    measurement: "",
    description: "",
    category: "",
    sub_category: "",
    variation: [],
    ...sel_product,
  });
  const [original, setoriginal] = useState();
  useEffect(() => {
    setoriginal(JSON.stringify(product_data));
  }, []);

  const [input_brands, setInput_brands] = useState("");
  const [meta, setMeta] = useState({});
  useEffect(() => {
    setMeta(db_product?._meta || {});
  }, [db_product]);

  const canUpdate = original !== JSON.stringify(product_data);
  return (
    <React.Fragment>
      {showConfirmation && (
        <PupConfirmation
          {...{
            title: "Remove Product",
            oc_cancel: () => setShowConfirmation(false),
            oc_confirm: async () => {
              await DelProduct(product_data.id);
              oc_close();
            },
          }}
        />
      )}
      <div className="shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.1)_0px_4px_6px_-4px]">
        <div className="px-8 py-3 border-b border-[#e2e8f0] flex flex-col">
          <div className="text-2xl font-semibold mb-4 my-4">Edit Product</div>
          <div className="grid grid-cols-[8rem_1fr_1fr] gap-8">
            <div className="">
              <div className="relative pt-[100%] h-0 bg-gray-200 overflow-hidden rounded-[0.25rem]">
                {product_data.sub_category && (
                  <img
                    src={
                      meta.category?.[product_data.category]?.sub_category.find(
                        (x) => x.name === product_data?.sub_category
                      )?.url
                    }
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <Input
                value={product_data.name}
                onChange={(e) => {
                  setProduct_data((prev) => {
                    const newState = {
                      ...prev,
                      name: e.target.value,
                    };
                    return newState;
                  });
                }}
                text="Product Name"
              />
              <Input
                value={product_data.measurement}
                onChange={(e) => {
                  setProduct_data((prev) => {
                    const newState = {
                      ...prev,
                      measurement: e.target.value,
                    };
                    return newState;
                  });
                }}
                text="Measurement"
              />
              <InputTextArea
                value={product_data.description}
                onChange={(e) => {
                  setProduct_data((prev) => {
                    const newState = {
                      ...prev,
                      description: e.target.value,
                    };
                    return newState;
                  });
                }}
                text="Description"
              />
            </div>
            <div className="flex flex-col">
              <div className="content sm:grid grid-cols-2 gap-4">
                <Dropdown
                  value={product_data.category}
                  onChange={(e) => {
                    if (e.target.value === "- Add Category -") {
                      setShowCategoryManager(true);
                      return;
                    }
                    setProduct_data((prev) => {
                      const newState = {
                        ...prev,
                        category: e.target.value,
                        sub_category: "",
                      };
                      return newState;
                    });
                  }}
                  options={[
                    ...Object.keys(meta.category || {}),
                    "- Add Category -",
                  ]}
                  text="Category"
                />
                <Dropdown
                  value={product_data.sub_category}
                  onChange={(e) => {
                    if (e.target.value === "- Add Sub Category -") {
                      setShowCategoryManager(true);
                      return;
                    }
                    setProduct_data((prev) => {
                      const newState = {
                        ...prev,
                        sub_category: e.target.value,
                      };
                      return newState;
                    });
                  }}
                  options={
                    product_data.category
                      ? [
                          ...((
                            meta.category?.[product_data.category ?? ""]
                              ?.sub_category || []
                          ).map((x) => x?.name) || []),
                          "- Add Sub Category -",
                        ]
                      : ["- Add Sub Category -"]
                  }
                  text="Sub Category"
                />
              </div>

              <div className="flex-1 flex flex-col">
                <div className="relative w-full mt-6 mb-4 ">
                  <div
                    className={`flex-1 min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 flex items-center`}
                  >
                    <div className="absolute left-0 top-[-25px] font-medium ">
                      Brands
                    </div>
                    <input
                      value={input_brands}
                      onChange={(e) => {
                        setInput_brands(e.target.value);
                      }}
                      type={"text"}
                      className={`flex-1 w-full h-full py-[14px] bg-transparent appearance-none outline-none `}
                    />
                    <div
                      onClick={async (e) => {
                        // check if include
                        if (input_brands.trim() === "") return;
                        if (!product_data?.variation.includes(input_brands)) {
                          setProduct_data((prev) => {
                            const newState = {
                              ...prev,
                              variation: [...prev.variation, input_brands],
                            };
                            return newState;
                          });
                        }
                        setInput_brands("");
                      }}
                      className="h-[40px] bg-[#4f46e5] flex-center text-white font-medium rounded-full px-5 cursor-pointer ml-4"
                    >
                      Add
                    </div>
                  </div>
                </div>
                <div className="bg-gray-100 min-h-[56px] rounded-[0.25rem] flex-1 mb-4 ">
                  <div className="flex flex-wrap p-2 gap-2">
                    {product_data.variation.map((x) => {
                      return (
                        <div className="a border-indigo-700 border px-4 py-2 rounded-[0.25rem] bg-indigo-700 text-white font-medium flex items-center">
                          {x}
                          <div
                            onClick={() => {
                              setProduct_data((prev) => {
                                const newState = {
                                  ...prev,
                                  variation: prev.variation.filter(
                                    (y) => y !== x
                                  ),
                                };
                                return newState;
                              });
                            }}
                            className="group cursor-pointer w-[24px] h-[24px] ml-4"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#fff"
                              className="group-hover:hidden block "
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                            </svg>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#fff"
                              className="group-hover:block hidden "
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                            </svg>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 py-4 flex justify-between">
          <div className="flex gap-4">
            <div
              onClick={() => oc_close()}
              className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition  hover:bg-[#e5e9ef] cursor-pointer"
            >
              Cancel
            </div>
            <div
              onClick={() => {
                setShowConfirmation(true);
              }}
              className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#dc2626] rounded-full border border-[#f5bebe] transition  hover:bg-[#fce9e9] cursor-pointer"
            >
              Delete
            </div>
          </div>

          <div
            onClick={async () => {
              // console.log(product_data);
              // const changes = _.differenceWith(
              //   _.toPairs(currentUser),
              //   _.toPairs(x),
              //   _.isEqual
              // );
              // // Changes in object form
              // const cdata = _.fromPairs(changes);
              // console.log(_.fromPairs(changes));
              // setCurrentUser(null);
              // await Update(product_data);
              // oc_close();
            }}
            className={`h-[40px] bg-[#4f46e5] flex-center text-white font-medium rounded-full px-5 cursor-pointer ${
              canUpdate ? "opacity-100" : "opacity-75"
            }`}
          >
            Update
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Button({ onClick, icon, className }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={
        className +
        (className.includes("absolute") ? "" : " relative ") +
        " group flex-center w-10 h-10 text-[#94a3b8] cursor-pointer rounded-full"
      }
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#94a3b833] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">{icon}</span>
    </div>
  );
}

function StatusDropdown({
  text,
  required,
  value,
  onChange,
  verify,
  changes,
  options,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <select
          value={value}
          onChange={onChange}
          className="w-full outline-none bg-transparent"
        >
          <option value={""}>Unpaid</option>
          <option value={"pending"}>Pending</option>
          <option value={"complete"}>Paid</option>
        </select>
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function Dropdown({
  text,
  required,
  value,
  onChange,
  verify,
  changes,
  options,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <select
          value={value}
          onChange={onChange}
          className="w-full outline-none bg-transparent capitalize"
        >
          <option className="hidden">-</option>
          {options &&
            options.map((x) => {
              return (
                <option key={x} value={x}>
                  {x}
                </option>
              );
            })}
        </select>
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function InputTextArea({
  text,
  required,
  value,
  changes,
  onChange,
  verify,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);

  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <textarea
          disabled={disabled}
          cols={4}
          value={value}
          onChange={onChange}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-transparent caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function Input({
  text,
  type = "text",
  required,
  value,
  onChange,
  verify,
  disabled = false,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  return (
    <div className="relative w-full mt-6 mb-4">
      <div
        className={`min-h-[48px] h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 bg-white ${
          disabled && "bg-gray-100"
        } ${
          hasError ? `border-[#dc2626]` : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          disabled={disabled}
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none caret-[${blue_theme}] `}
        />

        {type === "password" && (
          <div className="w-10 h-10 flex-none ml-1 mr-[-10px]">
            <Button
              icon={showPass ? "visibility_off" : "visibility"}
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function PupConfirmation({
  oc_cancel,
  oc_confirm,
  title = "Title",
  desc = "Are you sure you want to remove this content permanently?",
}) {
  return (
    <div className="fixed z-20 inset-0 bg-[#00000052] flex-center">
      <div className="w-[32rem] max-w-[75vw] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="w-full flex flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
          <div className="relative w-10 mr-4">
            <div
              className={`bg-transparent transition bg-[#fce9e9] w-10 h-10 rounded-full flex-center`}
            >
              <svg
                className="w-[24px] h-[24px]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="#dc2626"
                fill="transparent"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-full text-[18px] leading-6 font-medium ng-star-inserted pr-[2rem]">
              {title}
            </div>

            <div className="w-full text-[#64748b] leading-[21px] mt-1 text-[14px]">
              {desc}{" "}
              <span className="font-medium">This action cannot be undone!</span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-[#f8fafc]">
          <div
            onClick={oc_cancel}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition hover:bg-[#e5e9ef] cursor-pointer"
          >
            Cancel
          </div>

          <div
            onClick={oc_confirm}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-white rounded-full transition bg-[#dc2626] hover:bg-[#c62222] cursor-pointer"
          >
            Remove
          </div>
        </div>
      </div>
    </div>
  );
}
