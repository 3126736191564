import { UseSubscription } from "mFirebase/useAdminEvent";
import moment from "moment";
import React, { useState } from "react";

export default function SubscriptionTab() {
  const sb_subscription = UseSubscription();

  return (
    <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
      <div className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-6 sm:py-12 md:px-8 bg-white">
        <div className="relative flex flex-col">
          <div className="text-[32px] leading-[32px] font-extrabold">
            Email Subscription
          </div>
          <div className="text-[14px] mt-[3px] text-[#64748b] font-medium">
            {Object.keys(sb_subscription || {}).length} Subscriptions
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col border-t border-b border-[#cbd5e1] min-h-0 overflow-y-auto bg-white">
        <div className="sticky top-0 z-10 py-4 px-8 gap-4 grid grid-cols-[220px_1fr_50px] bg-[#f8fafc] text-[#64748b] font-semibold shadow-[rgba(0,0,0,0.1)_0px_1px_3px_0px,rgba(0,0,0,0.1)_0px_1px_2px_-1px]">
          <div className="capitalize">Receive At</div>
          <div className="capitalize">Email Address</div>
        </div>
        {!sb_subscription && (
          <div className="flex-1 flex-center">No Subscriptions Available</div>
        )}
        {sb_subscription &&
          Object.entries(sb_subscription || {}).map(
            ([subscription_id, subscription_obj], i, arr) => {
              return (
                <React.Fragment key={subscription_id}>
                  <div className="flex-none gap-4 grid grid-cols-[220px_1fr_50px] h-[70px]  px-8 items-center text-[14px] border-b border-[#cbd5e1] relative">
                    <div className="truncate">
                      {moment(subscription_obj?.createdAt).format("L LTS")}
                    </div>
                    <div className="truncate">{subscription_obj?.email}</div>
                    <div className="truncate capitalize flex-center"></div>
                  </div>
                </React.Fragment>
              );
            }
          )}
      </div>
    </div>
  );
}

function Input({
  text,
  type = "text",
  required,
  value,
  onChange,
  verify,
  changes,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          disabled={disabled}
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] bg-transparent appearance-none outline-none caret-[${blue_theme}]`}
        />

        {type === "password" && (
          <div className="w-10 h-10 flex-none ml-1 mr-[-10px]">
            <Button
              icon={showPass ? "visibility_off" : "visibility"}
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
function InputTextArea({
  text,
  required,
  value,
  changes,
  onChange,
  verify,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);

  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <textarea
          disabled={disabled}
          cols={4}
          value={value}
          onChange={onChange}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-transparent caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
function Button({ onClick, icon, className }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={
        className +
        (className.includes("absolute") ? "" : " relative ") +
        " group flex-center w-10 h-10 text-[#94a3b8] cursor-pointer rounded-full"
      }
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#94a3b833] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">{icon}</span>
    </div>
  );
}
