// structure = {
//   catalog: {
//     brand: {
//       id: "brand_name",
//     },
//     file: {
//       brand: {
//         id: "file_url",
//       },
//     },
//   },
// };

import {
  AddCatalog,
  AddCatalogBrand,
  DelCatalog,
  DelCatalogBrand,
  UseCatalog,
} from "mFirebase/useAdminEvent";
import React, { useState, useEffect } from "react";

export default function CatalogTab() {
  const db_catalog = UseCatalog();
  const [showAddBrand, setShowAddBrand] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [sel_brand, setSel_brand] = useState("");

  // Back to root when catalog removed
  useEffect(() => {
    !Object.values(db_catalog?.brand || {}).some((x) => x === sel_brand) &&
      setSel_brand("");
  }, [db_catalog, sel_brand]);

  return (
    <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
      {showAddBrand && (
        <PupAddBrand
          oc_cancel={() => {
            setShowAddBrand(false);
          }}
          oc_confirm={async (brand_name) => {
            const res = await AddCatalogBrand(brand_name);
            if (res.error) {
              console.log("pup error");
            } else {
              setShowAddBrand(false);
            }
          }}
        />
      )}

      {showUpload && (
        <PupUpload
          sel_brand={sel_brand}
          oc_cancel={() => {
            setShowUpload(false);
          }}
        />
      )}

      <div className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-6 sm:py-12 md:px-8 bg-white">
        <div className="relative flex flex-col">
          <div className="text-[32px] leading-[32px] font-extrabold">
            Catalog Manager
          </div>
          <div className="text-[14px] mt-[3px] text-[#64748b] font-medium">
            {db_catalog?.brand ? Object.entries(db_catalog.brand).length : 0}{" "}
            brands,{" "}
            {Object.values(db_catalog?.file || {}).reduce((p, v) => {
              const c = Object.keys(v).length;
              return p + c;
            }, 0)}{" "}
            catalogues
          </div>
        </div>
      </div>

      {/* ========== BRAND  ========== */}
      {!sel_brand && (
        <div className=" p-6 md:p-8 space-y-8 ">
          <div className="flex flex-wrap">
            {db_catalog?.brand &&
              Object.entries(db_catalog.brand).map(([key, val]) => {
                return (
                  <BrandButton
                    key={key}
                    {...{
                      brand_id: key,
                      brand_name: val,
                      catalog_count: db_catalog?.file?.[val]
                        ? Object.keys(db_catalog.file?.[val] || {}).length
                        : 0,
                      onClick: () => {
                        setSel_brand(val);
                      },
                      sel_brand: sel_brand,
                    }}
                  />
                );
              })}

            <AddButton onClick={() => setShowAddBrand(true)} />
          </div>
        </div>
      )}
      {/* ---------- BRAND  ---------- */}

      {/* ========== BRAND CATALOG ========== */}
      {sel_brand && (
        <div className="relative p-6 md:p-8 ">
          <div className="absolute left-0 top-2 text-[16px] mt-0 px-8 text-[#64748b] font-medium flex space-x-2">
            <div
              className="text-indigo-600 cursor-pointer"
              onClick={() => setSel_brand("")}
            >
              Home
            </div>
            {sel_brand && (
              <>
                <div className="a">/</div>
                <div className="a">{sel_brand}</div>
              </>
            )}
          </div>

          <div className="flex flex-wrap">
            {db_catalog?.file?.[sel_brand] &&
              Object.entries(db_catalog.file?.[sel_brand] || {}).map(
                ([key, val]) => {
                  return (
                    <CatalogButton
                      key={key}
                      data={{ key: key, val: val }}
                      sel_brand={sel_brand}
                    />
                  );
                }
              )}

            <UploadButton
              onClick={() => {
                setShowUpload(true);
              }}
            />
          </div>
        </div>
      )}
      {/* ---------- BRAND CATALOG ---------- */}
    </div>
  );
}

function BrandButton({
  onClick,
  brand_name = "Brand",
  catalog_count = 0,
  brand_id,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <React.Fragment>
      {showConfirmation && (
        <PupConfirmation
          oc_cancel={() => setShowConfirmation(false)}
          oc_confirm={async () => {
            await DelCatalogBrand({ brand_name, brand_id });
            setShowConfirmation(false);
          }}
          title="Remove brand"
          desc="Are you sure you want to remove this brand and all its content permanently?"
        />
      )}

      <div
        onClick={onClick}
        className="flex-none relative w-[160px] h-[160px] m-2 shadow rounded-2xl bg-white flex flex-col cursor-pointer"
      >
        {/* ========== CLOSE BTN ========== */}
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setShowConfirmation(true);
          }}
          icon="close"
          className="absolute top-1 right-1"
        />

        <div
          onClick={() => setShowConfirmation(true)}
          className="p-5 absolute inset-0 w-full h-full flex flex-col items-center justify-center"
        >
          <svg
            className="w-[56px] h-[56px] flex-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#94a3b8"
            fit=""
            height="100%"
            width="100%"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
            />
          </svg>

          <div className=" text-[#1e293b] text-[12px] leading-[18px] font-medium capitalize">
            {brand_name}
          </div>
          <div className="text-[#64748b] text-[12px] leading-[18px] font-medium">
            {catalog_count} catalogues
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function CatalogButton({ data, sel_brand }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <div className="flex-none relative w-[160px] h-[160px] m-2 shadow rounded-2xl bg-white flex flex-col cursor-pointer text-[20px]">
      {/* ========== CLOSE Pup ========== */}
      {showConfirmation && (
        <PupConfirmation
          oc_cancel={() => setShowConfirmation(false)}
          oc_confirm={async () => {
            await DelCatalog(sel_brand, data.key);
            setShowConfirmation(false);
          }}
          title="Remove catalog"
        />
      )}

      {/* ========== CLOSE BTN ========== */}
      <Button
        onClick={() => setShowConfirmation(true)}
        icon="close"
        className="absolute top-1 right-1"
      />

      <a
        href={data.val.url}
        download={data.val.file_name}
        target="_blank"
        className="p-5 absolute inset-0 w-full h-full flex flex-col items-center"
      >
        <div className="relative w-[56px] h-[84px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            stroke="#94a3b8"
            fill="transparent"
            height="100%"
            width="100%"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
          <div className="absolute -left-0 bottom-2 px-[0.375rem] rounded text-[12px] font-semibold leading-[20px] text-white bg-red-600">
            PDF
          </div>
        </div>
        <div className="w-full text-center flex-1 flex items-center justify-center text-[#1e293b] text-[12px] leading-[18px] font-medium capitalize truncate overflow-hidden min-w-0">
          <div className="truncate text-center"> {data.val.file_name}</div>
        </div>
      </a>
    </div>
  );
}

function AddButton({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="group relative w-[160px] h-[160px] m-2 shadow rounded-2xl border-dashed border-2 border-[#94a3b8] flex flex-col cursor-pointer"
    >
      <div className="p-5 absolute inset-0 w-full h-full flex flex-col items-center justify-center">
        <span className="material-symbols-rounded text-[40px] text-[#1e293b]">
          add
        </span>
        <div className="transition opacity-0 group-hover:opacity-100 absolute bottom-[20px] text-[#64748b] text-[12px] leading-[18px] font-medium ">
          Add Brand
        </div>
      </div>
    </div>
  );
}

function UploadButton({ onClick }) {
  return (
    <label
      onClick={onClick}
      className="group relative w-[160px] h-[160px] m-2 shadow rounded-2xl border-dashed border-2 border-[#94a3b8] flex flex-col cursor-pointer"
    >
      <div className="p-5 absolute inset-0 w-full h-full flex flex-col items-center justify-center">
        <span className="material-symbols-rounded text-[40px] text-[#1e293b]">
          add
        </span>
        <div className="transition opacity-0 group-hover:opacity-100 absolute bottom-[20px] text-[#64748b] text-[12px] leading-[18px] font-medium ">
          Upload Catalog
        </div>
      </div>
    </label>
  );
}

function PupAddBrand({ oc_cancel }) {
  const [brandName, setBrandName] = useState("");

  async function oc_submit() {
    const res = await AddCatalogBrand(brandName);
    if (res.error) {
      console.log("pup error");
    } else {
      oc_cancel();
    }
  }

  return (
    <div className="fixed z-10 inset-0 bg-[#00000052] flex-center">
      <div className="w-[32rem] max-w-[75vw] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="w-full flex flex-col  flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
          <div className="w-full text-[18px] leading-6 font-bold ng-star-inserted pr-[2rem]">
            Add Brand
          </div>

          <div className="w-full mt-4">
            <Input
              value={brandName}
              onChange={(e) => {
                setBrandName(e.target.value);
              }}
              text="Brand Name"
              required
            />
          </div>
        </div>

        <div className="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-[#f8fafc]">
          <div
            onClick={oc_cancel}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition hover:bg-[#e5e9ef] cursor-pointer"
          >
            Cancel
          </div>

          <div
            onClick={() => oc_submit()}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-white rounded-full transition bg-indigo-600 hover:bg-[#473fce] cursor-pointer"
          >
            Create
          </div>
        </div>
      </div>
    </div>
  );
}

function PupUpload({ oc_cancel, sel_brand }) {
  const [data, setData] = useState({ file_name: "", file: "" });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState("");

  async function oc_submit() {
    if (isLoading) return;
    setError("");
    // sanitize data
    if (data.file_name === "") {
      return setError("Catalog name is required");
    }
    if (!data.file) {
      return setError("Select PDF is required");
    }
    setIsLoading(true);
    await AddCatalog(sel_brand, data, setProgress);
    setIsLoading(false);
    oc_cancel();
  }
  return (
    <div className="fixed z-40 inset-0 bg-[#00000052] flex-center">
      <div className="w-[32rem] max-w-[75vw] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="w-full flex flex-col  flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
          <div className="w-full text-[18px] leading-6 font-bold ng-star-inserted pr-[2rem]">
            Add Catalog{" "}
          </div>

          <div className="w-full mt-4">
            <Input
              value={data.file_name}
              onChange={(e) => {
                setData((prev) => {
                  const newState = { ...prev };
                  newState.file_name = e.target.value;
                  return newState;
                });
              }}
              text="Catalog Name"
              required
            />
          </div>

          <div className="w-full">
            <InputFile
              progress="30%"
              onChange={(e) => {
                setData((prev) => {
                  const newState = { ...prev };
                  newState.file = e.target.files[0];
                  return newState;
                });
              }}
              text="Select PDF"
              required
            />
          </div>
        </div>

        <div className="flex items-center justify-between px-6 py-4  bg-[#f8fafc]">
          <div className="a">
            <div className="text-[14px] text-[#dc2626]"> {error && error}</div>

            {isLoading && (
              <div className="text-[14px] text-[#1e293b]">
                Upload progress: {progress}
              </div>
            )}
          </div>

          <div className={`flex space-x-3 ${isLoading && "opacity-60"}`}>
            <div
              onClick={() => {
                if (isLoading) return;
                oc_cancel();
              }}
              className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition hover:bg-[#e5e9ef] cursor-pointer"
            >
              Cancel
            </div>

            <button
              onClick={() => oc_submit()}
              className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-white rounded-full transition bg-indigo-600 hover:bg-[#473fce] cursor-pointer"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// function PupAddBrand() {
//     return (
//       <div className="fixed z-10 inset-0 bg-[#00000052] flex-center">
//         <div className="w-[32rem] max-w-[75vw] bg-white flex flex-col rounded-[16px] overflow-hidden">
//           <div className="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
//             <div className="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
//               <div className="text-xl leading-6 font-medium ng-star-inserted">
//                 Remove contact
//               </div>

//               <div className="text-secondary ng-star-inserted">
//                 Are you sure you want to remove this contact permanently?{" "}
//                 <span className="font-medium">This action cannot be undone!</span>
//               </div>
//             </div>
//           </div>

//           <div className="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-[#f8fafc]">
//             <div className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition hover:bg-[#e5e9ef] cursor-pointer">
//               Cancel
//             </div>

//             <div className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-white rounded-full transition bg-[#dc2626] hover:bg-[#c62222] cursor-pointer">
//               Remove
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

function Input({ text, type = "text", required, value, onChange, verify }) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  return (
    <div className="relative w-full mt-6 mb-4">
      <div
        className={`min-h-[48px] h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError ? `border-[#dc2626]` : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-white caret-[${blue_theme}]`}
        />

        {type === "password" && (
          <div className="w-10 h-10 flex-none ml-1 mr-[-10px]">
            <Button
              icon={showPass ? "visibility_off" : "visibility"}
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function InputFile({ text, required, value, onChange, verify, progress }) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={` rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError ? `border-[#dc2626]` : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          value={value}
          onChange={onChange}
          type="file"
          accept="application/pdf"
          className={`flex-1 w-full h-[46px] py-[10px] leading-[10px] my-auto  appearance-none outline-none bg-white caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function Button({ onClick, icon, className }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={
        className +
        (className.includes("absolute") ? "" : " relative ") +
        " group flex-center w-10 h-10 text-[#94a3b8] cursor-pointer rounded-full"
      }
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#94a3b833] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">{icon}</span>
    </div>
  );
}

function ObjectToArray(obj, name = "id") {
  if (!obj) return [];
  return Object.entries(obj).map(([key, val]) => {
    return {
      [name]: key,
      data: val,
    };
  });
}

function PupConfirmation({
  oc_cancel,
  oc_confirm,
  title = "Title",
  desc = "Are you sure you want to remove this content permanently?",
}) {
  return (
    <div className="fixed z-20 inset-0 bg-[#00000052] flex-center">
      <div className="w-[32rem] max-w-[75vw] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="w-full flex flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
          <div className="relative w-10 mr-4">
            <div
              className={`bg-transparent transition bg-[#fce9e9] w-10 h-10 rounded-full flex-center`}
            >
              <svg
                className="w-[24px] h-[24px]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="#dc2626"
                fill="transparent"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-full text-[18px] leading-6 font-medium ng-star-inserted pr-[2rem]">
              {title}
            </div>

            <div className="w-full text-[#64748b] leading-[21px] mt-1 text-[14px]">
              {desc}{" "}
              <span className="font-medium">This action cannot be undone!</span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-[#f8fafc]">
          <div
            onClick={oc_cancel}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition hover:bg-[#e5e9ef] cursor-pointer"
          >
            Cancel
          </div>

          <div
            onClick={oc_confirm}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-white rounded-full transition bg-[#dc2626] hover:bg-[#c62222] cursor-pointer"
          >
            Remove
          </div>
        </div>
      </div>
    </div>
  );
}
