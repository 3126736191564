import React from "react";
import { Link } from "react-router-dom";
import { useImages } from "component/hook/useImages";
import { GenID, UpdateDB, UseDB } from "mFirebase/useAdminEvent";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import _, { filter } from "lodash";
import useLocalStorage from "component/hook/useLocalStorage";
import { analytics } from "mFirebase/useFirebaseApp";
import { logEvent } from "firebase/analytics";
import { increment } from "firebase/database";
const { search } = require("fast-fuzzy");

export default function Product() {
  const params = useParams();
  const [product_id, setproduct_id] = useState("");
  const db_product = UseDB("/product/list");
  const db_category = UseDB("/product/_meta/category");
  const [productImage, setproductImage] = useState({});
  const [current_product, setcurrent_product] = useState("");
  const [quotation, setquotation] = useState({
    product_id: params.product_id,
    quantity: 1,
    variation: "",
    detail: "",
  });
  useEffect(() => {
    if (analytics && current_product) {
      logEvent(analytics, "view_product", { product_id: params.product_id });
      const id = GenID();
      UpdateDB(`analytic`, { [current_product.name]: increment(1) });
    }
  }, [analytics, current_product]);

  useEffect(() => {
    setquotation({
      product_id: params.product_id,
      quantity: 1,
      variation: "",
      detail: "",
    });
    setproduct_id(params.product_id);
  }, [params]);

  useEffect(() => {
    const d = {};
    _.entries(db_category).map(([category_name, category_obj]) => {
      category_obj.sub_category.map((x) => {
        if (!d[category_name]) d[category_name] = {};
        d[category_name][x.name] = x.url;
      });
    });
    setproductImage(d);
  }, [db_category]);

  useEffect(() => {
    if (!db_product) return;
    setcurrent_product(db_product[product_id]);
    setquotation((p) => {
      return {
        ...p,
        detail: db_product[product_id],
        variation: db_product[product_id].variation?.[0] || "",
      };
    });
  }, [db_product, product_id]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [current_product]);

  const [store_quotation_list, setstore_quotation_list] = useLocalStorage(
    "quotation_list",
    []
  );

  const [predict, setPredict] = useState("");
  useEffect(() => {
    if (!db_product) return;
    const a = search(
      db_product[product_id].name,
      _.entries(db_product).map(([key, val]) => {
        return { ...val, id: key };
      }),
      {
        keySelector: (obj) => obj.name,
      }
    );
    setPredict(a.slice(1, 6));
  }, [current_product, db_product]);

  if (!current_product)
    return <div className="min-h-[50vh] flex-center">loading...</div>;
  return (
    <React.Fragment>
      <div className="flex-1 container mt-[2em] mb-[6em] xl:pb-[3rem] mx-auto flex md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 flex-col ">
        <div className="flex mb-4">
          <Link to="/shop">
            <div className="w-full xl:w-auto flex-center cursor-pointer  uppercase py-1 text-[1em] font-semibold rounded-[0.5em] gap-2 bg-blue-ci text-white px-4 ">
              <div className="a">{svg_back}</div> {" Back to shop"}
            </div>
          </Link>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-[1fr_2fr] w-full gap-[2em]">
          {/* ----- IMG ----- */}
          <div className="a">
            <div className="border border-[#eee] pt-[100%] relative overflow-hidden  rounded-[0.5em]">
              <img
                src={
                  productImage?.[db_product[product_id].category]?.[
                    db_product[product_id].sub_category
                  ]
                }
                alt=""
                className="w-full h-full object-contain absolute inset-0 "
              />
            </div>
          </div>
          {/* ----- DETAIL ----- */}
          <div className="flex flex-col">
            <div className="font-semibold text-[2em]">
              {current_product?.name}
            </div>
            <div className="mt-[0.5em] text-[#00000088] font-light text-[1.2em] flex-1">
              {current_product?.description}
            </div>

            <div className="mt-[3em] flex gap-2 flex-wrap">
              {current_product?.variation &&
                current_product?.variation.map((x) => {
                  return (
                    <div
                      key={x}
                      onClick={() => {
                        setquotation((p) => {
                          return { ...p, variation: x };
                        });
                      }}
                      className={`border border-[00000088] px-4 h-[50px] font-medium flex-center min-w-[150px] uppercase hover:border-blue-ci cursor-pointer rounded-[0.5rem] ${
                        quotation.variation === x
                          ? " bg-blue-ci text-white"
                          : ""
                      }`}
                    >
                      {x}
                    </div>
                  );
                })}
            </div>
            <div className="mt-[1.5rem]">
              <div className="flex-1 flex items-center">
                Details & Dimension{" "}
                <span className="material-symbols-rounded">navigate_next</span>
                <span className="lowercase">
                  {current_product?.measurement?.replaceAll("X", " x ")} (mm)
                </span>
              </div>
              <div className="w-full h-px bg-[#eeeeee] mb-[1em] mt-[0.5em]"></div>

              <div className="mt-[1em] border border-[#eee] flex py-[1em] p-[1.5em] rounded-[0.5em]">
                <div className="flex-1 flex items-center">Quantity</div>
                <div className="flex">
                  <div
                    onClick={() => {
                      setquotation((p) => {
                        return {
                          ...p,
                          quantity: _.clamp(p.quantity - 1, 1, 999),
                        };
                      });
                    }}
                    className="border rounded-full h-[2em] w-[2em] flex-center cursor-pointer select-none"
                  >
                    -
                  </div>
                  <div className="h-[2em] w-[2em] flex-center font-bold">
                    {quotation.quantity}
                  </div>
                  <div
                    onClick={() => {
                      setquotation((p) => {
                        return {
                          ...p,
                          quantity: _.clamp(p.quantity + 1, 1, 999),
                        };
                      });
                    }}
                    className="border rounded-full h-[2em] w-[2em] flex-center cursor-pointer select-none"
                  >
                    +
                  </div>
                </div>
              </div>

              <div className="flex gap-4 justify-end mt-[3em]">
                <div
                  onClick={() => {
                    const f = store_quotation_list.findIndex(
                      (x) =>
                        x.product_id === product_id &&
                        x.variation === quotation.variation
                    );
                    if (f !== -1) {
                      setstore_quotation_list((p) => {
                        const newState = [...p];
                        newState[f].quantity = _.clamp(
                          newState[f].quantity + quotation.quantity,
                          0,
                          999
                        );
                        return newState;
                      });
                    } else {
                      setstore_quotation_list((p) => {
                        return [...p, quotation];
                      });
                    }
                    alert("Added to list");
                  }}
                  className="w-full xl:w-auto flex-center cursor-pointer bg-blue-ci py-[1em] px-[4em] uppercase text-white text-[1em] font-semibold rounded-[0.5em]"
                >
                  Add to quotation list
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----- PRODUCT ----- */}
      <div className="flex flex-col py-[0.15em]   overflow-hidden bg-[#f8f8f8] shadow-[inset_0px_0px_20px_0px_#00000033]">
        <div className=" container  md:max-w-[1200px] 2xl:max-w-[1440px] mx-auto px-6 lg:px-12">
          <div className="flex items-center bg-blue-ci w-[11em] lg:w-[15em] h-[7em] rounded-br-[1.5em] relative">
            <div className="uppercase text-white font-medium text-[1.5em] leading-[1.3em] relative">
              Similar <br /> product
            </div>
            <div className="absolute right-full h-full bg-blue-ci w-[50vw]"></div>
          </div>
        </div>
      </div>

      {predict && (
        <div className="mt-[6em] flex flex-col space-y-[8em] lg:space-y-0 items-center lg:grid grid-cols-5 md:gap-[4em] 2xl:gap-[7em] container mx-auto md:max-w-[1200px] 2xl:max-w-[1440px] px-12 mb-[8em]">
          <SimilarItem {...{ predict }} />
        </div>
      )}
    </React.Fragment>
  );
}

const svg_back = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="24"
    width="24"
    fill="#fff"
  >
    <path d="M24 40 8 24 24 8l2.1 2.1-12.4 12.4H40v3H13.7l12.4 12.4Z" />
  </svg>
);

function SimilarItem({ predict }) {
  const db_category = UseDB("/product/_meta/category");
  const [productImage, setproductImage] = useState({});
  useEffect(() => {
    const d = {};
    _.entries(db_category).map(([category_name, category_obj]) => {
      category_obj.sub_category.map((x) => {
        if (!d[category_name]) d[category_name] = {};
        d[category_name][x.name] = x.url;
      });
    });
    setproductImage(d);
  }, [db_category]);

  return predict.map((x) => {
    console.log(x);
    return (
      <div key={x.id} className="relative w-[60%] lg:w-auto">
        {/* ----- IMG  ----- */}
        <div className="pt-[100%] relative">
          <div className="absolute w-full h-full top-0 left-0 overflow-hidden rounded-full border-[#a6a6a6] border-[0.2em] bg-white flex-center">
            <img
              src={productImage?.[x.category]?.[x.sub_category]}
              className="w-4/5 h-4/5 object-contain "
            />
          </div>
        </div>

        <div className="mt-[2em] flex flex-col">
          <div className="truncate">{x.name}</div>
          <div className="truncate text-[#00000088] capitalize">
            {x.category}
          </div>
        </div>

        <Link to={`/product/${x.id}`}>
          <div className="mt-[1em] capitalize border-[0.15em] border-[#ccc] text-[#636363] font-light rounded-[0.5em] flex-center h-[3.5em] cursor-pointer hover:bg-blue-ci hover:text-white">
            details
          </div>
        </Link>
      </div>
    );
  });
}
