import useInterval from "component/hook/useInterval";
import CurrentTime from "component/CurrentTime";

import React, { useState, useEffect } from "react";

import { _Input } from "component/form/UiLibrary";

import CatalogTab from "page/Dashboard/CatalogTab";
import CareerTab from "./CareerTab";
import { TAB_NAME } from "./Info";
import QuotationTab from "./QuotationTab";
import InquiryTab from "./InquiryTab";
import SubscriptionTab from "./SubscriptionTab";
import ProductTab from "./ProductTab";
import LeadsTab from "./LeadsTab";
import OverallTab from "./OverallTab";

var _ = require("lodash");
const moment = require("moment");

export default function Content({ setShowSidebar, currentTab }) {
  return (
    <div className="flex flex-col flex-auto w-full min-w-0 bg-[#f1f5f9]">
      <div className="relative z-10 flex flex-0 items-center justify-between w-full h-16 px-4 md:px-6 z-49 shadow bg-white">
        <ButtonIcon
          onClick={() => {
            setShowSidebar((prev) => !prev);
          }}
        />

        <div className="flex">
          <div className="font-[14px] font-medium text-gray-600 font-mono py-2 px-4 rounded bg-gray-200">
            <CurrentTime />
          </div>
        </div>
      </div>

      {currentTab === TAB_NAME[0] && <OverallTab />}
      {currentTab === TAB_NAME[1] && <QuotationTab />}
      {currentTab === TAB_NAME[2] && <InquiryTab />}

      {/* {currentTab === TAB_NAME[3] && <LeadsTab />} */}
      {currentTab === TAB_NAME[3] && <SubscriptionTab />}

      {currentTab === TAB_NAME[4] && <ProductTab />}
      {currentTab === TAB_NAME[5] && <CatalogTab />}
      {currentTab === TAB_NAME[6] && <CareerTab />}
    </div>
  );
}

function ButtonIcon({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="group relative flex-center w-10 h-10 text-[#64748b] cursor-pointer  rounded-full "
    >
      <div className="opacity-0 group-hover:opacity-100 transition absolute bg-[#e2e8f0] w-full h-full rounded-full"></div>
      <span className="z-10 material-symbols-rounded">menu</span>
    </div>
  );
}
