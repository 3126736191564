function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

export const useImages = () =>
  importAll(
    require.context("../../asset/img", false, /\.(png|jpe?g|svg|webp)$/)
  );

export const useHomeImages = () =>
  importAll(
    require.context("../../asset/img/home", false, /\.(png|jpe?g|svg|webp)$/)
  );

export const useCatalogImages = () =>
  importAll(
    require.context("../../asset/img/catalog", false, /\.(png|jpe?g|svg|webp)$/)
  );
export const useCareerImages = () =>
  importAll(
    require.context("../../asset/img/career", false, /\.(png|jpe?g|svg|webp)$/)
  );
