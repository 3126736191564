import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  reload,
} from "firebase/auth";
import { doc, increment, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";
import { useFirebaseApp } from "./useFirebaseApp";

let global_setUser;

const useFireUserImpl = () => {
  const [user, setUser] = useState(null);
  let app = useFirebaseApp();
  let auth;
  useEffect(() => {
    if (!app) return;
    global_setUser = setUser;
    auth = getAuth();
    console.log("FIREBASE @ listening to authentication");
    onAuthStateChanged(auth, (userData) => {
      if (userData) {
        setUser({ isReady: true, user: userData });
        console.log("FIREBASE @ signed in");
      } else {
        setUser({ isReady: true });
        console.log("FIREBASE @ not signed in");
      }
    });
  }, [app]);

  return user;
};

export const useFireUser = singletonHook(null, useFireUserImpl);

export const FireSignOut = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};

export async function CreateFireUser(obj) {
  const auth = getAuth();

  try {
    // create account
    const id = obj.ic_number + "@user.com";
    const password = obj.phone_number;

    await createUserWithEmailAndPassword(auth, id, password);

    return { isSuccess: true };
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(error);
    switch (errorCode) {
      case "auth/email-already-in-use":
        return {
          isSuccess: false,
          errorMessage: "This IC has been registered",
        };
        break;
      // case "auth/invalid-email":
      //   return {
      //     isSuccess: false,
      //     errorMessage: "This email is invalid",
      //   };
      //   break;
      case "auth/wrong-password":
        return {
          isSuccess: false,
          errorMessage: "This phone number is invalid",
        };
        break;

      default:
        return {
          isSuccess: false,
          errorMessage: "Unknown error, please contact 0182051754",
        };
        break;
    }
  }
}

export const FireSignIn = async (email, password) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return { isSuccess: true };
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);

    switch (errorCode) {
      case "auth/invalid-email":
        return { isSuccess: false, errorMessage: "Invalid IC" };
        break;
      case "auth/user-not-found":
        return { isSuccess: false, errorMessage: "IC not registered" };
        break;
      case "auth/wrong-password":
        return {
          isSuccess: false,
          errorMessage: "This phone number is invalid",
        };
        break;
      case "auth/too-many-requests":
        return {
          isSuccess: false,
          errorMessage:
            "Too many failed attempts, please contact 0182051754 for technical assistance",
        };
        break;

      default:
        return { isSuccess: false, errorMessage: "Unknown error" };
        break;
    }
  }
};
