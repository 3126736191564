import React, { useState, useEffect, useCallback } from "react";

const isBrowser = typeof window !== "undefined";

const isLocalstorageAvailable = () => {
  if (!isBrowser) {
    return false;
  }

  const test = `test-${Date.now()}`;
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export default function useLocalStorage(key, initialValue) {
  const available = isLocalstorageAvailable();
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Listen to localstorage change to apply it
  const changeHandler = useCallback(
    (e) => {
      const { key: changeKey, newValue } = e;
      if (key === changeKey) {
        setStoredValue(JSON.parse(newValue));
      }
    },
    [key]
  );

  // Listen changes
  useEffect(() => {
    if (available) {
      window.addEventListener("storage", changeHandler);
      return () => {
        window.removeEventListener("storage", changeHandler);
      };
    }
  }, [available]); // eslint-disable-line react-hooks/exhaustive-deps

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
      window.dispatchEvent(
        new StorageEvent("storage", {
          key,
          newValue: JSON.stringify(valueToStore),
        })
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}
