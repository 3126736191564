import { ref } from "firebase/database";
import React, { useState, useEffect, useRef } from "react";

import { FireSignIn } from "mFirebase/useFireUser";
import { useImages } from "component/hook/useImages";

const text_primary = "text-[#6366f1]";
const theme_primary = "#4f46e5";
const bg_primary = "bg-[#4f46e5] hover:bg-[#473fce]";
export default function LoginPage() {
  const images = useImages();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [loginError, setloginError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });

  async function oc_signin(e) {
    e.preventDefault();
    if (isLoading) return;
    setError("");
    setloginError("");
    const email = formData.email;
    const password = formData.password;

    // sanitize input
    if (email === "") {
      setError("IC number is required");
      return;
    }
    if (password === "") {
      setError("Phone number is required");
      return;
    }

    // start execute
    // setIsLoading(true);

    const res = await FireSignIn(email, password);
    // setIsLoading(false);

    if (!res.isSuccess) {
      // setError(res.errorMessage);
      setIsVerify(true);
      setloginError("Wrong email or password");
      setError("invalid");
    }
  }
  return (
    <div className="w-screen min-h-screen flex-center bg-[#f1f5f9] text-[14px]">
      {/* ----- LOGIN CONTAINER ----- */}
      <div className="w-full  flex-center sm:justify-center min-w-0 md:p-8">
        {/* ----- FLEX CONTAINER ----- */}
        <div className="flex items-stretch md:w-full md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden sm:bg-card">
          {/* ----- LEFT ----- */}
          <div className="bg-transparent sm:bg-white w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
            <div className="h-12 w-32">
              <img
                src={images["logo.png"]}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
              <div className="mt-8 text-4xl font-extrabold tracking-tight leading-tight ng-tns-c309-139">
                Sign in
              </div>
              <div className="flex items-baseline mt-0.5 font-medium ng-tns-c309-139">
                <div className="ng-tns-c309-139">
                  Access limited to internal
                </div>
                {/* <a
              className="ml-1 text-primary-500 hover:underline "
              href="/sign-up"
            >
              Sign up{" "}
            </a> */}
              </div>

              {loginError && (
                <div className="mt-8 -mb-4 rounded-md p-4 bg-[#fef2f2] text-[#b91c1c] ring-1 ring-[#f87171]">
                  Wrong email or password
                </div>
              )}

              <form onSubmit={oc_signin} className="mt-8 flex flex-col">
                <Input
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  text="Email address"
                  required
                  verify={
                    isVerify
                      ? (val) => {
                          if (val.length < 1) {
                            return "Email is required";
                          }
                        }
                      : null
                  }
                />
                <Input
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                  }}
                  text="Password"
                  required
                  type="password"
                  verify={
                    isVerify
                      ? (val) => {
                          if (val.length < 1) {
                            return "Password is required";
                          }
                        }
                      : null
                  }
                />

                <div className="mt-1.5 flex justify-between">
                  <Checkbox />

                  <a
                    className={`text-md font-medium text-primary-500 hover:underline ${text_primary}`}
                    href="/forgot-password"
                  >
                    Forgot password?{" "}
                  </a>
                </div>

                <div className="h-[80px]"></div>
                <button
                  className={`mt-6 h-12 min-h-12 max-h-12 rounded-full w-full font-medium ${bg_primary} flex-center text-white`}
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
          {/* _____ LEFT _____ */}

          {/* ----- RIGHT ----- */}
          <div className="relative hidden md:flex flex-auto items-center justify-center p-16 lg:px-28 overflow-hidden bg-gray-800 ">
            <svg
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute inset-0 pointer-events-none ng-tns-c130-86"
            >
              <g
                fill="none"
                stroke="currentColor"
                strokeWidth={100}
                className="text-gray-700 opacity-25 ng-tns-c130-86"
              >
                <circle r={234} cx={196} cy={23} className="ng-tns-c130-86" />
                <circle r={234} cx={790} cy={491} className="ng-tns-c130-86" />
              </g>
            </svg>
            <svg
              viewBox="0 0 220 192"
              width={220}
              height={192}
              fill="none"
              className="absolute -top-16 -right-16 text-gray-700 ng-tns-c130-86"
            >
              <defs className="ng-tns-c130-86">
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                  className="ng-tns-c130-86"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    fill="currentColor"
                    className="ng-tns-c130-86"
                  />
                </pattern>
              </defs>
              <rect
                width={220}
                height={192}
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                className="ng-tns-c130-86"
              />
            </svg>

            <div className="z-10 relative w-full max-w-2xl ng-tns-c130-86">
              <div className="text-5xl font-bold text-gray-100 leading-[48px]">
                <div className="">Buttersquad</div>
                <div className="">admin panel</div>
              </div>
              <div className="mt-6 text-base tracking-tight leading-6 text-[#94a3b8] ">
                Buttersquad helps companies and events managers to build
                customized system for various purpose. Contact us to get a
                tailored services for your event or day to day needs.
              </div>

              <div className="h-[20px]"></div>
            </div>
          </div>
          {/* _____ RIGHT _____ */}
        </div>
        {/* _____ FLEX CONTAINER _____ */}
      </div>
      {/* _____ LOGIN CONTAINER _____ */}
    </div>
  );
}

function Checkbox({ checked = true }) {
  return (
    <label htmlFor="" className="flex items-center cursor-pointer">
      <span className="material-symbols-rounded text-[20px]">
        {checked ? "check_box" : "check_box_outline_blank"}
      </span>
      <span className="mat-checkbox-label ml-2">Remember me </span>
      <input type="checkbox" checked={checked} name="" className="hidden" />
    </label>
  );
}

function Input({ text, type = "text", required, value, onChange, verify }) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError ? `border-[#dc2626]` : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-white caret-[${blue_theme}]`}
        />

        {type === "password" && (
          <div className="w-10 h-10 flex-none ml-1 mr-[-10px]">
            <Button
              icon={showPass ? "visibility_off" : "visibility"}
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function Button({ onClick, icon }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className="group relative flex-center w-10 h-10 text-[#94a3b8] cursor-pointer rounded-full"
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#94a3b833] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">{icon}</span>
    </div>
  );
}
