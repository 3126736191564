import { Link } from "react-router-dom";

import {
  useHomeImages,
  useImages,
  useCatalogImages,
} from "component/hook/useImages";
import useWindowSize from "component/hook/useWindowSize";
import React, { useEffect, useState } from "react";
import { UseCatalog } from "mFirebase/useAdminEvent";

export default function CatalogPage() {
  const db_catalog = UseCatalog();
  const [sel_brand, setSel_brand] = useState("");
  const images = useImages();
  const win = useWindowSize();
  const catalogImages = useCatalogImages();

  useEffect(() => {
    window.scrollTo(0, 0);
    setSel_brand(Object.values(db_catalog?.brand || {})[0]);
  }, [db_catalog]);

  return (
    <React.Fragment>
      <div className="lg:min-h-screen -mt-[4.5em] flex flex-col">
        {/* ----- HEADER ----- */}
        <div className="relative h-[6em] flex mt-[7em] lg:mt-[8.5em]">
          <div className="relative bg-blue-ci w-[12em] sm:w-[20em] lg:w-[24em] 2xl:w-[30em] rounded-br-[3em] h-[4em] text-white font-bold flex items-center ">
            <div className="absolute right-full h-full bg-blue-ci w-[50vw]"></div>
          </div>
          <div className="flex-1 rounded-tl-[3em] overflow-hidden h-[4em] self-end text-white font-bold flex items-center">
            <img
              src={catalogImages["banner.webp"]}
              className="w-full h-full object-cover object-center "
            />
          </div>
          <div className="absolute container flex items-center text-white   md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 left-1/2 -translate-x-1/2 h-[4em]">
            <div className="text-[1.4em] sm:text-[1.7em] h-full font-semibold flex items-center uppercase">
              Catalog
            </div>
          </div>
        </div>
        {/* _____ HEADER _____ */}

        {/* ----- TAB ----- */}
        <div className="mt-[4em] lg:mt-[8em] container  md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 mx-auto ">
          <div className="flex flex-wrap gap-[0.8em] lg:gap-[1em]">
            {Object.values(db_catalog?.brand || {}).map((x) => {
              return (
                <div
                  onClick={() => setSel_brand(x)}
                  key={x}
                  className="min-w-[8em] h-[3em] px-[2em] flex-center overflow-hidden border-[2px] border-[#0098d733]  cursor-pointer relative rounded-full"
                >
                  {sel_brand === x && (
                    <img
                      src={images["btn_shop.png"]}
                      className="absolute inset-0 w-full h-full -z-10 object-cover"
                    />
                  )}
                  {sel_brand !== x ? (
                    <div className="text-sm lg:text-[1em] font-bold text-blue-ci truncate">
                      {x}
                    </div>
                  ) : (
                    <div className="text-sm lg:text-[1em] font-bold text-white truncate">
                      {x}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {/* _____ TAB _____ */}

        {/* ----- CATALOG LIST ----- */}
        <div className="mt-[4em] container  md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 mx-auto ">
          <div className="flex flex-col space-y-[1em]">
            {Object.values(db_catalog?.file?.[sel_brand] || {}).map((x) => {
              return (
                <CatalogItem {...{ file_name: x.file_name, url: x.url }} />
              );
            })}
          </div>
          {/* _____ CATALOG LIST _____ */}
        </div>

        <div className="mt-[5em] lg:mt-[10em]"></div>
      </div>
    </React.Fragment>
  );
}

function CatalogItem({ file_name, url }) {
  return (
    <div
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 12px 15px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
      }}
      className="h-[5em] rounded-[0.5em] bg-white px-[2em] flex justify-between items-center"
    >
      <div className="capitalize font-medium text-blue-ci">{file_name}</div>
      <div className="flex text-blue-ci ">
        <a
          href={url}
          target="_blank"
          download
          className="w-[2.5em] h-[2.5em] relative flex-center group"
        >
          <div className="group-hover:opacity-100 opacity-0 transition absolute inset-0 bg-[#0098d715] rounded-full"></div>
          <span className="relative material-symbols-rounded text-[1.75em] cursor-pointer">
            download
          </span>
        </a>

        <a
          href={url}
          target="_blank"
          className="ml-[2em] w-[2.5em] h-[2.5em] relative flex-center group"
        >
          <div className="group-hover:opacity-100 opacity-0 transition absolute inset-0 bg-[#0098d715] rounded-full"></div>
          <span className="relative material-symbols-rounded text-[1.75em] cursor-pointer">
            navigate_next
          </span>
        </a>
      </div>
    </div>
  );
}
