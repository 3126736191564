import React, { useState, useEffect } from "react";

import { useImages } from "component/hook/useImages";
import { useParams } from "react-router-dom";
import { UseDB } from "mFirebase/useAdminEvent";
import _, { filter } from "lodash";
import { Link } from "react-router-dom";
const { search } = require("fast-fuzzy");

export default function ShopPage() {
  const params = useParams();
  const images = useImages();
  const db_product = UseDB("/product/list");
  const db_category = UseDB("/product/_meta/category");

  const [sel_category, setsel_category] = useState({
    category: "",
    sub_category: "",
  });

  const [productImage, setproductImage] = useState({});
  useEffect(() => {
    const d = {};
    _.entries(db_category).map(([category_name, category_obj]) => {
      category_obj.sub_category.map((x) => {
        if (!d[category_name]) d[category_name] = {};
        d[category_name][x.name] = x.url;
      });
    });
    setproductImage(d);
  }, [db_category]);

  const [predict, setPredict] = useState([]);
  const [filter_text, setfilter_text] = useState(params.filter || "");
  const [filtered_list, setfiltered_list] = useState([]);
  const [showPredict, setshowPredict] = useState(false);
  useEffect(() => {
    const a = search(
      filter_text,
      _.entries(db_product).map(([key, val]) => {
        return { ...val, id: key };
      }),
      {
        keySelector: (obj) => obj.name + obj.measurement,
      }
    );
    setPredict(a.slice(0, 10));
    setfiltered_list(a);
    setsel_category({
      category: "",
      sub_category: "",
    });
    setCurrent_page(0);
  }, [filter_text, db_product]);

  useEffect(() => {
    if (sel_category.category) {
      if (sel_category.sub_category) {
        setfiltered_list(
          _.entries(db_product)
            .map(([key, val]) => {
              return { ...val, id: key };
            })
            .filter((x) => x.sub_category === sel_category.sub_category)
        );
      } else {
        setfiltered_list(
          _.entries(db_product)
            .map(([key, val]) => {
              return { ...val, id: key };
            })
            .filter((x) => x.category === sel_category.category)
        );
      }
    } else {
      if (!filter_text)
        setfiltered_list(
          _.entries(db_product).map(([key, val]) => {
            return { ...val, id: key };
          })
        );
    }
    setCurrent_page(0);
  }, [db_product, sel_category, filter_text]);

  useEffect(() => {}, [sel_category]);

  const [current_page, setCurrent_page] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [db_category]);

  return (
    <React.Fragment>
      {/* ----- SEARCH ----- */}
      <div className="flex-1 container mt-[4em] mb-[1em] lg:mb-[3em] mx-auto flex items-center md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 ">
        {showPredict && (
          <div
            onClick={() => setshowPredict(false)}
            className="left-0 top-0 fixed z-10 w-full h-full"
          ></div>
        )}
        {/* ----- SEARCH BAR ----- */}
        <div className="bg-white relative z-10 h-[4em] flex-1 flex items-center border rounded-[0.4em] px-[2em] shadow-[inset_0px_2px_9px_3px_#00000022]">
          <input
            value={filter_text}
            onChange={(e) => {
              setfilter_text(e.target.value);
            }}
            onFocus={() => setshowPredict(true)}
            type="text"
            placeholder="search product..."
            className=" bg-transparent appearance-none outline-none  w-full h-full text-[1em] placeholder:text-blue-ci"
          />
          <div
            className={`${
              showPredict ? "flex" : "hidden"
            } absolute z-10 w-full left-0 top-full flex-col bg-gray-200 border border-gray-300 divide-y-2 rounded-b-[0.5rem] overflow-hidden `}
          >
            {predict.length < 1 && (
              <div className="py-3 px-6 bg-white cursor-pointer ">
                <span className="font-semibold capitalize text-center text-gray-500">
                  - No Product found -
                </span>
              </div>
            )}
            {predict.map((x) => {
              return (
                <div
                  onClick={() => {
                    setfilter_text(x.name);
                    setshowPredict(false);
                  }}
                  className="py-3 px-6 bg-white cursor-pointer hover:bg-gray-100"
                >
                  <span className="font-bold capitalize">
                    {x.sub_category} :{" "}
                  </span>
                  {x.name}
                  {x.measurement && (
                    <span className="text-[#00000077]">
                      [{x.measurement.toLowerCase()}]
                    </span>
                  )}
                </div>
              );
            })}
          </div>

          <div className="ml-[2em] w-[1.3em] h-[1.3em]">
            <img
              src={images["icon_search.svg"]}
              className="w-full h-full cursor-pointer"
              alt="cart"
            />
          </div>
        </div>
      </div>
      {/* _____ SEARCH _____ */}

      {/* ----- SHOP ----- */}
      <div className="min-h-[100vh] container mx-auto md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 flex flex-col lg:flex-row mb-[8em]">
        <SideBar {...{ sel_category, setsel_category }} />
        <div className="lg:ml-[4em] flex-1 mt-[4em] lg:mt-0">
          <div className="h-[6vh] flex items-center justify-between">
            <div className="text-gray-500 "></div>
            <div className="text-gray-500 flex items-center gap-2">
              {Math.min(current_page * 50 + 1, filtered_list.length)}-
              {Math.min((current_page + 1) * 50, filtered_list.length)} of{" "}
              {filtered_list.length}
              <span
                onClick={() =>
                  setCurrent_page((prev) =>
                    _.clamp(prev - 1, 0, _.floor(filtered_list.length / 50))
                  )
                }
                className="w-[40px] h-[40px] flex-center rounded-full hover:bg-gray-100 transition-all cursor-pointer"
              >
                {svg_prev}
              </span>
              <span
                onClick={() =>
                  setCurrent_page((prev) =>
                    _.clamp(prev + 1, 0, _.floor(filtered_list.length / 50))
                  )
                }
                className="w-[40px] h-[40px] flex-center rounded-full hover:bg-gray-100 transition-all cursor-pointer"
              >
                {svg_next}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[4em] mt-2">
            {filtered_list
              .slice(current_page * 50, (current_page + 1) * 50)
              .map((x) => {
                return (
                  <div className="flex justify-center lg:justify-end">
                    <div className="relative w-full lg:max-w-[190px] flex flex-col">
                      {/* ----- IMG  ----- */}
                      <div className="pt-[100%] relative">
                        <div className="absolute overflow-hidden w-full h-full top-0 left-0 rounded-full border-[#a6a6a6] border-[0.2em] bg-white flex-center">
                          <img
                            src={productImage?.[x.category]?.[x.sub_category]}
                            className="w-4/5 h-4/5 object-contain"
                          />
                        </div>
                      </div>

                      <div className="flex-1 mt-[1em] flex flex-col">
                        <div className="">{x.name}</div>
                        <div className="text-[#00000088] capitalize text-sm">
                          {x.sub_category}
                        </div>
                      </div>

                      <Link to={`/product/${x.id}`}>
                        <div className="mt-[1em] capitalize border-[0.15em] border-[#ccc] text-[#636363] font-light rounded-[0.5em] flex-center h-[3.5em] cursor-pointer hover:bg-blue-ci hover:text-white text-center">
                          Details
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="h-[6vh] flex items-center justify-between">
            <div className="text-gray-500 "></div>
            <div className="text-gray-500 flex items-center gap-2">
              {Math.min(current_page * 50 + 1, filtered_list.length)}-
              {Math.min((current_page + 1) * 50, filtered_list.length)} of{" "}
              {filtered_list.length}
              <span
                onClick={() =>
                  setCurrent_page((prev) =>
                    _.clamp(prev - 1, 0, _.floor(filtered_list.length / 50))
                  )
                }
                className="w-[40px] h-[40px] flex-center rounded-full hover:bg-gray-100 transition-all cursor-pointer"
              >
                {svg_prev}
              </span>
              <span
                onClick={() =>
                  setCurrent_page((prev) =>
                    _.clamp(prev + 1, 0, _.floor(filtered_list.length / 50))
                  )
                }
                className="w-[40px] h-[40px] flex-center rounded-full hover:bg-gray-100 transition-all cursor-pointer"
              >
                {svg_next}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="flex-center">No Product Found</div> */}
      </div>
      {/* _____ SHOP _____ */}
    </React.Fragment>
  );
}

function Drawer({
  text,
  count,
  expanded = false,
  children,
  onClick = () => {},
}) {
  return (
    <>
      <div
        onClick={() => onClick(text)}
        className="group h-[4vh] flex items-center rounded-[1vh] cursor-pointer text-sm"
      >
        <div
          className={`flex items-center flex-1 capitalize ${
            expanded && "font-bold text-blue-400"
          }`}
        >
          {text} <span className=" text-gray-500 ml-[1vh]">({count})</span>
        </div>

        <div className="w-[3vh] h-[3vh] flex-center group-hover:bg-gray-200 rounded-full">
          {expanded ? (
            <span className="material-symbols-rounded">expand_less</span>
          ) : (
            <span className="material-symbols-rounded">expand_more</span>
          )}
        </div>
      </div>
      <div
        className={`flex flex-col transition-all overflow-hidden ${
          expanded ? "max-h-auto" : "max-h-0"
        }`}
      >
        {children}
      </div>
    </>
  );
}

function Content({ text, count, sel_category, onClick }) {
  return (
    <>
      <div
        onClick={onClick}
        className={`h-[4vh] pl-[2vh] flex items-center hover:bg-gray-200 ${
          sel_category.sub_category === text && "bg-gray-200"
        } rounded-[1vh] cursor-pointer text-sm`}
      >
        <div className="flex items-center flex-1 capitalize">
          {text} <span className=" text-gray-500 ml-[1vh]">({count})</span>
        </div>
      </div>
    </>
  );
}

function SideBar({ sel_category, setsel_category }) {
  const [expanded, setExpanded] = useState(false);
  const db_category = UseDB("product/_meta/category");
  const db_product = UseDB("product/list");
  const [category_count, setcategory_count] = useState({});
  useEffect(() => {
    const c = {};
    const list = _.values(db_product);
    _.entries(db_category).map(([category_name, sub_category_obj]) => {
      c[category_name] = list.filter(
        (item) => item.category === category_name
      )?.length;
      sub_category_obj.sub_category.map((sub) => {
        c[sub.name] = list.filter((x) => x.sub_category === sub.name).length;
      });
    });
    setcategory_count(c);
  }, [db_product]);

  function handle_expand(drawer_name) {
    setExpanded((prev) => {
      if (prev === drawer_name) {
        setsel_category((prev) => {
          return { category: "", sub_category: "" };
        });
        return false;
      } else {
        setsel_category((prev) => {
          return { ...prev, category: drawer_name };
        });
        return drawer_name;
      }
    });
  }

  return (
    <div className="w-full lg:max-w-[300px] flex flex-col px-2 lg:px-0">
      <div className="flex flex-col bg-yellow-200 p-4">
        <div className="grid grid-cols-[60px_3fr]">
          <div className="font-bold">ZZ :</div>
          <div className="a">Bearing with a metal seal on both sides</div>
        </div>

        <div className="grid grid-cols-[60px_3fr]">
          <div className="font-bold">LL :</div>
          <div className="a">Bearing with rubber seal on both sides</div>
        </div>

        <div className="grid grid-cols-[60px_3fr]">
          <div className="font-bold">NR :</div>
          <div className="a">
            Snap ring groove in the outer ring, with snap ring
          </div>
        </div>
        <div className="grid grid-cols-[60px_3fr]">
          <div className="font-bold">ZNR :</div>
          <div className="a">
            Bearing with a metal seal on one side, snap ring groove in the outer
            ring, with snap ring
          </div>
        </div>
        <div className="grid grid-cols-[60px_3fr]">
          <div className="font-bold">ZZNR :</div>
          <div className="a">
            Bearing with a metal seal on both sides, snap ring groove in the
            outer ring, with snap ring
          </div>
        </div>
      </div>

      <div className="uppercase font-bold h-[6vh] border-b flex items-center  mt-[4em]  ">
        <div className="flex-1">Categories</div>
        <span className="material-symbols-rounded">expand_less</span>
      </div>
      {_.entries(db_category).map(([category_name, category_obj]) => {
        return (
          <Drawer
            key={category_name}
            text={category_name}
            count={category_count?.[category_name]}
            expanded={expanded === category_name}
            onClick={handle_expand}
          >
            {category_obj.sub_category.map((x) => {
              return (
                <Content
                  key={x.name}
                  text={x.name}
                  count={category_count?.[x.name]}
                  sel_category={sel_category}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setsel_category((prev) => {
                      return { category: category_name, sub_category: x.name };
                    });
                  }}
                />
              );
            })}
          </Drawer>
        );
      })}
    </div>
  );
}

const svg_prev = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
    viewBox="0 0 48 48"
  >
    <path d="m26.95 34.9-9.9-9.9q-.25-.25-.35-.5-.1-.25-.1-.55 0-.3.1-.55.1-.25.35-.5L27 12.95q.45-.45 1.075-.45t1.075.45q.45.45.425 1.1-.025.65-.475 1.1l-8.8 8.8 8.85 8.85q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.1.45-.65 0-1.1-.45Z" />
  </svg>
);
const svg_next = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
    viewBox="0 0 48 48"
  >
    <path d="M17.7 34.9q-.4-.5-.425-1.1-.025-.6.425-1.05l8.8-8.8-8.85-8.85q-.4-.4-.375-1.075.025-.675.425-1.075.5-.5 1.075-.475.575.025 1.025.475l9.95 9.95q.25.25.35.5.1.25.1.55 0 .3-.1.55-.1.25-.35.5l-9.9 9.9q-.45.45-1.05.425-.6-.025-1.1-.425Z" />
  </svg>
);
