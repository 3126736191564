import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useImages } from "component/hook/useImages";
import useLocalStorage from "component/hook/useLocalStorage";
import { UseDB } from "mFirebase/useAdminEvent";
import _ from "lodash";
import { motion } from "framer-motion";
export default function Header() {
  const images = useImages();
  const loc = useLocation();
  const [store_quotation_list, setstore_quotation_list] = useLocalStorage(
    "quotation_list",
    []
  );
  const [showMenu, setshowMenu] = useState(false);
  useEffect(() => {
    console.log("showMenu", showMenu);
  }, [showMenu]);

  return (
    <React.Fragment key="header">
      {/* ----- HEADER ----- */}
      <div
        className="sticky h-[6em] bg-[#ffffffee] top-0 z-20 flex items-center"
        style={{
          boxShadow: "0px 5px 20px 0px #0000001a",
        }}
      >
        <div className=" container px-6 lg:px-12 mx-auto flex items-center justify-between md:max-w-[1200px] 2xl:max-w-[1440px] ">
          {/* ----- LOGO ----- */}
          <Link to={"/"} className="flex items-center">
            <img
              src={images["logo.png"]}
              className="w-[7em] lg:w-[8em] h-full object-contain"
              alt="shengyi_logo"
            />
            <p className="uppercase text-[#c1c1c1] font-bold ml-[1em] text-[0.9em] hidden lg:block">
              sheng yi bearing industries sdn bhd
            </p>
            <p className="uppercase text-[#c1c1c1] font-bold ml-[1em] text-[0.9em] leading-[1.2em]  lg:hidden">
              sheng yi
              <br className="a" /> bearing industries
            </p>
          </Link>

          {/* ----- NAV BTN ----- */}
          <div className=" items-center space-x-[2em] capitalize hidden lg:flex">
            <Link
              to={"/"}
              className={`group relative flex-center flex-center ${
                loc.pathname === "/" ? "text-blue-ci" : "text-[#a6a6a6]"
              }`}
            >
              <span className="material-symbols-rounded text-[1.65em]">
                home
              </span>
              <div
                className="absolute opacity-0 left-1/2 -translate-x-1/2 top-[120%] transition group-hover:opacity-100 min-w-[6em] h-[3em] bg-white flex-center rounded-[0.5em]"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Home
              </div>
            </Link>
            <Link
              to={"/shop"}
              className={`group relative flex-center ${
                loc.pathname === "/shop" ? "text-blue-ci" : "text-[#a6a6a6]"
              }`}
            >
              <span className="material-symbols-rounded text-[1.5em]">
                storefront
              </span>
              <div
                className="absolute opacity-0 left-1/2 -translate-x-1/2 top-[120%] transition group-hover:opacity-100 min-w-[6em] h-[3em] bg-white flex-center rounded-[0.5em] "
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Store
              </div>
            </Link>
            <Link
              to={"/catalog"}
              className={`group relative flex-center ${
                loc.pathname === "/catalog" ? "text-blue-ci" : "text-[#a6a6a6]"
              }`}
            >
              <span className="material-symbols-rounded text-[1.5em]">
                description
              </span>
              <div
                className="absolute opacity-0 left-1/2 -translate-x-1/2 top-[120%] transition group-hover:opacity-100 min-w-[6em] h-[3em] bg-white flex-center rounded-[0.5em] "
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Catalog
              </div>
            </Link>
            <Link
              to={"/about-us"}
              className={`group relative flex-center ${
                loc.pathname === "/about-us" ? "text-blue-ci" : "text-[#a6a6a6]"
              }`}
            >
              <span className="material-symbols-rounded text-[1.5em]">
                group
              </span>
              <div
                className="absolute opacity-0 left-1/2 -translate-x-1/2 top-[120%] transition group-hover:opacity-100 min-w-[6em] h-[3em] bg-white flex-center rounded-[0.5em] "
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                About us
              </div>
            </Link>
            <Link
              to={"/career"}
              className={`group relative flex-center ${
                loc.pathname === "/career" ? "text-blue-ci" : "text-[#a6a6a6]"
              }`}
            >
              <span className="material-symbols-rounded text-[1.5em]">
                work
              </span>
              <div
                className="absolute opacity-0 left-1/2 -translate-x-1/2 top-[120%] transition group-hover:opacity-100 min-w-[6em] h-[3em] bg-white flex-center rounded-[0.5em] "
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Career
              </div>
            </Link>
            <div className="group flex-center relative ">
              {/* <img
                src={images["btn_cart.svg"]}
                className="w-full h-full"
                alt="cart"
              /> */}
              <Link to="/checkout">{svg_cart}</Link>

              <div className="absolute -top-[0.5em] -right-[0.5em] bg-red-600 w-[1.1em] h-[1.1em] flex-center text-white text-[1em] rounded-full ">
                <div className="text-[0.8em]">
                  {store_quotation_list.length}
                </div>{" "}
              </div>
              <CartList />
            </div>

            <a
              href="https://api.whatsapp.com/send?phone=60162102293"
              target={"_blank"}
              className="flex items-center text-blue-ci"
            >
              <svg
                viewBox="0 0 32 32"
                className="w-[1.8em] h-[1.8em]"
                fill="#0098d7"
              >
                <path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"></path>
              </svg>
              <p className="ml-[0.5em]"> 016-210 2293</p>
            </a>
          </div>

          <div className="flex lg:hidden">
            <div className="ml-[2em] w-[2.5em] h-[2.5em]  relative group flex justify-center items-center lg:hidden">
              <div className="group flex-center relative ">
                <Link to="/checkout">
                  <span className="material-symbols-rounded text-[1.5em]">
                    shopping_cart
                  </span>
                </Link>
                <CartList />

                <div className="absolute -top-[0.5em] -right-[0.5em] bg-red-600 w-[1.1em] h-[1.1em] flex-center text-white text-[1em] rounded-full">
                  <div className="text-[0.8em]">
                    {store_quotation_list.length}
                  </div>{" "}
                </div>
              </div>
            </div>

            {/* ----- Menu ----- */}
            <div
              onClick={() => {
                console.log("click");
                setshowMenu(!showMenu);
              }}
              className="ml-[0.5em] w-[2.5em] h-[2.5em]  relative group flex justify-center items-center lg:hidden"
            >
              <div className="group-hover:opacity-100 opacity-0 transition absolute inset-0 bg-[#00000011] rounded-full"></div>
              <span className="relative material-symbols-rounded text-[1.75em] cursor-pointer">
                menu
              </span>
            </div>

            <motion.div
              initial={{ maxHeight: "0px" }}
              animate={showMenu ? { maxHeight: "221px" } : { maxHeight: "0px" }}
              transition={{ duration: 0.2 }}
              style={{
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              className={`absolute w-full top-full left-0 bg-[#ffffffee] overflow-hidden border `}
            >
              {["", "shop", "catalog", "about-us", "career"].map((x) => {
                let isSelected = false;
                if (loc.pathname === `/` && x === "") isSelected = true;
                if (loc.pathname === `/shop` && x === "shop") isSelected = true;
                if (loc.pathname === `/catalog` && x === "catalog")
                  isSelected = true;
                if (loc.pathname === `/about-us` && x === "about-us")
                  isSelected = true;
                if (loc.pathname === `/career` && x === "career")
                  isSelected = true;
                return (
                  <Link key={x} to={`/${x}`}>
                    <div
                      onClick={() => setshowMenu(false)}
                      className={`a py-3 border-b px-8 capitalize cursor-pointer ${
                        isSelected ? "text-blue-ci font-bold" : "text-[#a6a6a6]"
                      }`}
                    >
                      {x === "" && "Home"}
                      {x === "shop" && "Shop"}
                      {x === "catalog" && "Catalog"}
                      {x === "about-us" && "About Us"}
                      {x === "career" && "Career"}
                    </div>
                  </Link>
                );
              })}
            </motion.div>
          </div>
        </div>
      </div>

      {/* _____ HEADER _____ */}
    </React.Fragment>
  );
}

function CartList() {
  const db_category = UseDB("/product/_meta/category");
  const [productImage, setproductImage] = useState({});
  useEffect(() => {
    const d = {};
    _.entries(db_category).map(([category_name, category_obj]) => {
      category_obj.sub_category.map((x) => {
        if (!d[category_name]) d[category_name] = {};
        d[category_name][x.name] = x.url;
      });
    });
    setproductImage(d);
  }, [db_category]);

  const [store_quotation_list, setstore_quotation_list] = useLocalStorage(
    "quotation_list",
    []
  );
  const [quotation_list, setquotation_list] = useState([]);

  useEffect(() => {
    setquotation_list(store_quotation_list);
  }, [store_quotation_list]);

  return (
    <div className="transition flex opacity-0 pointer-events-none group-hover:pointer-events-auto hover:pointer-events-auto group-hover:opacity-100 group-hover:flex hover:flex absolute gap-4 w-[100vw] xl:w-[500px] top-[100%] -right-[70px] xl:-right-10 flex-col z-30">
      <div
        style={{
          boxShadow:
            "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
        }}
        className="bg-white p-[1.5em] mt-[1rem] pr-0 rounded-[0.5rem]"
      >
        <div className="flex flex-col max-h-[45vh] overflow-auto min-h-0 gap-4 pr-6">
          {quotation_list.length < 1 && (
            <Link to={"/shop"}>
              <div className="h-[30vh] flex-center text-gray-300 text-sm border rounded-[0.5rem] mb-4 cursor-pointer hover:text-gray-500">
                Cart is empty, press here to visit shop now
              </div>
            </Link>
          )}
          {quotation_list.map((x, i) => {
            return (
              <div
                key={i}
                className="flex gap-4 border-b border-[#00000055] pb-4 flex-none"
              >
                <img
                  src={
                    productImage?.[x.detail.category]?.[x.detail.sub_category]
                  }
                  alt=""
                  className="w-28 h-28 object-contain border rounded-[0.5rem] overflow-hidden flex-none"
                />
                <div className="flex flex-col w-full">
                  <div className="flex border-b pb-4 items-center">
                    <div className="font-semibold flex-1">
                      <div className="a">
                        {x.detail.name} -{" "}
                        <span className="font-light uppercase">
                          {x?.variation}
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setstore_quotation_list((p) => {
                          const newState = _.cloneDeep(p);
                          newState[i].quantity = _.clamp(
                            p[i].quantity - 1,
                            1,
                            999
                          );
                          return newState;
                        });
                      }}
                      className="border rounded-full h-[2em] w-[2em] flex-center cursor-pointer select-none"
                    >
                      -
                    </div>
                    <div className="h-[2em] w-[2em] flex-center font-bold">
                      {x.quantity}
                    </div>
                    <div
                      onClick={() => {
                        setstore_quotation_list((p) => {
                          const newState = _.cloneDeep(p);
                          newState[i].quantity = _.clamp(
                            p[i].quantity + 1,
                            1,
                            999
                          );
                          return newState;
                        });
                      }}
                      className="border rounded-full h-[2em] w-[2em] flex-center cursor-pointer select-none"
                    >
                      +
                    </div>
                  </div>
                  <div className="flex flex-1">
                    <div className="pt-2 flex-1 font-light text-[#00000055] leading-5">
                      {x.detail.category} / {x.detail.sub_category}
                    </div>
                    <div
                      onClick={() => {
                        setstore_quotation_list((p) => {
                          const newState = _.cloneDeep(p).filter(
                            (n) => n.product_id !== x.product_id
                          );

                          return newState;
                        });
                      }}
                      className="flex-none ml-4 self-end cursor-pointer mb-1 mr-1"
                    >
                      {svg_delete}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Link to="/checkout">
          <div className=" cursor-pointer bg-blue-ci py-[1em] px-[4em] uppercase text-white text-[1em] font-semibold text-sm rounded-[0.5em] text-center mr-6">
            Go Checkout
          </div>
        </Link>
      </div>
    </div>
  );
}

const svg_delete = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="20"
    width="20"
    fill="#ff0000"
  >
    <path d="M13.05 42q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z" />
  </svg>
);

const svg_cart = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="23"
    width="23"
    fill="#000000"
  >
    <path d="M14.35 43.95q-1.5 0-2.55-1.05-1.05-1.05-1.05-2.55 0-1.5 1.05-2.55 1.05-1.05 2.55-1.05 1.45 0 2.525 1.05t1.075 2.55q0 1.5-1.05 2.55-1.05 1.05-2.55 1.05Zm20 0q-1.5 0-2.55-1.05-1.05-1.05-1.05-2.55 0-1.5 1.05-2.55 1.05-1.05 2.55-1.05 1.45 0 2.525 1.05t1.075 2.55q0 1.5-1.05 2.55-1.05 1.05-2.55 1.05Zm-22.6-33 5.5 11.4h14.4l6.25-11.4Zm-1.5-3H39.7q1.6 0 2.025.975.425.975-.275 2.175L34.7 23.25q-.5.85-1.4 1.475-.9.625-1.95.625H16.2l-2.8 5.2h24.55v3h-24.1q-2.1 0-3.025-1.4-.925-1.4.025-3.15l3.2-5.9L6.45 7h-3.9V4H8.4Zm7 14.4h14.4Z" />
  </svg>
);
