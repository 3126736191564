import useInterval from "component/useInterval";

import React, { useState, useEffect } from "react";

import useDebounce from "component/useDebounce";
import {
  AddCategory,
  AddCategoryImage,
  AddProduct,
  UpdateDB,
  UpdateSubCategory,
  UseDB,
  UseProduct,
} from "mFirebase/useAdminEvent";
import { FacebookAuthProvider } from "firebase/auth";
import ProductDetail from "./ProductTab/ProductDetail";
var _ = require("lodash");
const moment = require("moment");

export default function ProductTab() {
  const db_product = UseProduct();
  const [sel_product, setsel_product] = useState("");
  const [product_list, setProduct_list] = useState([]);
  const [showCategoryManager, setShowCategoryManager] = useState(false);
  const [meta, setMeta] = useState();

  useEffect(() => {
    const p = Object.entries(db_product?.list || {}).map(([key, val]) => {
      return { ...val, id: key };
    });

    setProduct_list(p);
    setMeta(db_product?._meta || { count: 0 });
  }, [db_product]);

  const [filter, setFilter] = useState("");
  const deb_filter = useDebounce(filter, 300);

  const [filtered_list, setFiltered_list] = useState([]);
  useEffect(() => {
    if (deb_filter) {
      setFiltered_list(
        product_list.filter((x) => _.values(x).join("").includes(deb_filter))
      );
    } else
      setFiltered_list(
        _.sortBy(product_list, (x) => {
          return !x.featured;
        })
      );
  }, [deb_filter, product_list]);

  const [showNewProduct, setShowNewProduct] = useState(false);

  return (
    <React.Fragment>
      {showCategoryManager && (
        <PupCategory
          {...{ meta: meta, oc_close: () => setShowCategoryManager(false) }}
        />
      )}
      <div className="relative flex-1 flex flex-col items-stretch overflow-y-auto ">
        <div className="bg-white border-b border-[#e2e8f0]">
          <div className="relative flex items-center sm:justify-between py-8 px-8">
            <div className="flex flex-col">
              <div className="font-extrabold text-[2rem] capitalize ">
                Product List
              </div>
              {/* <div className="flex ">
              <div className="h-[28px] bg-red-400 rounded-full px-4 flex-center text-white">
                All{" "}
              </div>

              <div className="h-[28px] bg-red-400 rounded-full px-4 flex-center text-white">
                Joined Challenge
              </div>

              <div className="h-[28px] bg-red-400 rounded-full px-4 flex-center text-white">
                Joined Poster
              </div>

              <div className="h-[28px] bg-red-400 rounded-full px-4 flex-center text-white">
                Joined Poster
              </div>
            </div> */}
            </div>

            <div className="flex items-center">
              <div className="min-h-[40px] border border-[#cbd5e1] rounded-full px-4 flex items-stretch">
                <div className="flex-center text-[#94a3b8]">
                  <span className="material-symbols-rounded w-5 h-5">
                    search
                  </span>
                </div>
                <input
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value.toLowerCase());
                  }}
                  type="text"
                  name=""
                  className="ml-3 appearance-none outline-none"
                  placeholder="Search item"
                />
              </div>
              <div
                onClick={() => setShowNewProduct(true)}
                className="bg-[#4f46e5] text-white px-[20px] h-[40px] rounded-full cursor-pointer flex items-center ml-4"
              >
                <span className="material-symbols-rounded">add</span>
                <div className="ml-2 mr-1 font-medium">Add</div>
              </div>

              {/* <div
                onClick={() => setShowNewProduct(true)}
                className="border-[#4f46e5] text-[#4f46e5] border-2 w-[40px] h-[40px] rounded-full cursor-pointer flex-center ml-4"
              >
                <div className="ml-2 mr-1 font-medium">
                  <span className="material-symbols-rounded">
                    keyboard_arrow_down
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="flex-1 sm:mb-[4.5rem] bg-white flex flex-col min-h-0">
          <div className="flex-1 flex flex-col overflow-auto min-h-0 border-b border-[#e2e8f0]">
            {/* HEADER */}
            <div className="sticky top-0 z-10 py-4 px-8 gap-4 grid grid-cols-[3fr_2fr_2fr_100px_80px] bg-[#f8fafc] text-[#64748b] font-semibold shadow-[rgba(0,0,0,0.1)_0px_1px_3px_0px,rgba(0,0,0,0.1)_0px_1px_2px_-1px]">
              <div className="capitalize">name</div>
              <div className="capitalize">category</div>
              <div className="capitalize">sub category</div>
              <div className="truncate flex-center capitalize">featured</div>
              <div className="capitalize flex-center">edit</div>
            </div>

            {showNewProduct && (
              <NewProduct
                {...{
                  oc_close: () => setShowNewProduct(false),
                  meta: meta,
                  setShowCategoryManager,
                }}
              />
            )}
            {!showNewProduct && filtered_list.length === 0 && (
              <div className="flex-center flex-shrink-0 flex-grow">
                No product found
              </div>
            )}
            {filtered_list.slice(0, 500).map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <div
                    className={`py-4 px-8 gap-4 grid grid-cols-[3fr_2fr_2fr_100px_80px]  border-b border-[#e2e8f0] hover:bg-gray-50 ${
                      sel_product &&
                      sel_product.id !== x.id &&
                      " text-[#1e293b40]"
                    } ${
                      sel_product &&
                      sel_product.id === x.id &&
                      "font-semibold bg-gray-50 text-[#1e293b]"
                    }`}
                  >
                    <div className="truncate">{x?.name}</div>
                    <div className="truncate">{x?.category}</div>
                    <div className="truncate">{x?.sub_category}</div>
                    <div
                      onClick={() => {
                        if (x.featured) {
                          UpdateDB(`product/list/${x.id}`, { featured: false });
                          return;
                        }
                        UpdateDB(`product/list/${x.id}`, { featured: true });
                      }}
                      className="truncate flex-center cursor-pointer"
                    >
                      {x.featured ? star_full : star}
                    </div>
                    <div className="truncate capitalize flex-center">
                      <div
                        onClick={() => {
                          if (sel_product.id !== x.id) setsel_product(x);
                          else setsel_product("");
                        }}
                        className="text-[#64748b] border border-[#cbd5e1] rounded-full px-2 h-7 flex-center cursor-pointer hover:bg-[#94a3b833]"
                      >
                        <span className="material-symbols-rounded">
                          {sel_product.id === x.id
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </div>
                    </div>
                  </div>

                  {sel_product.id === x.id && (
                    <ProductDetail
                      {...{
                        sel_product,
                        setShowCategoryManager,
                        oc_close: () => setsel_product(""),
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>

          <div className="absolute w-full h-[4.5rem] px-8 text-[#64748b] bottom-0 bg-[#f8fafc] flex justify-end items-center">
            {/* <div className="flex-center">Items count: {filteredData.length}</div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function NewProduct({ meta, setShowCategoryManager, oc_close }) {
  const [input_brands, setInput_brands] = useState("");
  const [productData, setProductData] = useState({
    name: "",
    measurement: "",
    description: "",
    category: "",
    sub_category: "",
    variation: [],
  });

  return (
    <div className="shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.1)_0px_4px_6px_-4px]">
      <div className="px-8 py-3 border-b border-[#e2e8f0] flex flex-col">
        <div className="text-2xl font-semibold mb-4 my-4">New Product</div>
        <div className="grid grid-cols-[8rem_1fr_1fr] gap-8">
          <div className="">
            <div className="relative pt-[100%] h-0 bg-gray-200 overflow-hidden rounded-[0.25rem]">
              {productData.sub_category && (
                <img
                  src={
                    meta.category?.[productData.category]?.sub_category.find(
                      (x) => x.name === productData?.sub_category
                    )?.url
                  }
                  className="absolute inset-0 w-full h-full object-cover"
                />
              )}
            </div>
            {/* <div className="pt-[100%] relative">
              <div className="absolute inset-0 rounded-[0.25rem] border border-[#cbd5e1] bg-[#fafbfc]">
                {productData.sub_category && (
                  <img
                    src={
                      meta.category?.[productData.category]?.sub_category.url
                    }
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
            </div> */}
          </div>
          <div className="flex flex-col">
            <Input
              value={productData.name}
              onChange={(e) => {
                setProductData((prev) => {
                  const newState = {
                    ...prev,
                    name: e.target.value,
                  };
                  return newState;
                });
              }}
              text="Product Name"
            />
            <Input
              value={productData.measurement}
              onChange={(e) => {
                setProductData((prev) => {
                  const newState = {
                    ...prev,
                    measurement: e.target.value,
                  };
                  return newState;
                });
              }}
              text="Measurement"
            />
            <InputTextArea
              value={productData.description}
              onChange={(e) => {
                setProductData((prev) => {
                  const newState = {
                    ...prev,
                    description: e.target.value,
                  };
                  return newState;
                });
              }}
              text="Description"
            />
          </div>
          <div className="flex flex-col">
            <div className="content sm:grid grid-cols-2 gap-4">
              <Dropdown
                value={productData.category}
                onChange={(e) => {
                  if (e.target.value === "- Add Category -") {
                    setShowCategoryManager(true);
                    return;
                  }
                  setProductData((prev) => {
                    const newState = {
                      ...prev,
                      category: e.target.value,
                    };
                    return newState;
                  });
                }}
                options={[
                  ...Object.keys(meta.category || {}),
                  "- Add Category -",
                ]}
                text="Category"
              />
              <Dropdown
                value={productData.sub_category}
                onChange={(e) => {
                  if (e.target.value === "- Add Sub Category -") {
                    setShowCategoryManager(true);
                    return;
                  }
                  setProductData((prev) => {
                    const newState = {
                      ...prev,
                      sub_category: e.target.value,
                    };
                    return newState;
                  });
                }}
                options={
                  productData.category
                    ? [
                        ...((
                          meta.category?.[productData.category ?? ""]
                            ?.sub_category || []
                        ).map((x) => x?.name) || []),
                        "- Add Sub Category -",
                      ]
                    : ["- Add Sub Category -"]
                }
                text="Sub Category"
              />
            </div>

            <div className="flex-1 flex flex-col">
              <div className="relative w-full mt-6 mb-4 ">
                <div
                  className={`flex-1 min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 flex items-center`}
                >
                  <div className="absolute left-0 top-[-25px] font-medium ">
                    Brands
                  </div>
                  <input
                    value={input_brands}
                    onChange={(e) => {
                      setInput_brands(e.target.value);
                    }}
                    type={"text"}
                    className={`flex-1 w-full h-full py-[14px] bg-transparent appearance-none outline-none `}
                  />
                  <div
                    onClick={async (e) => {
                      // check if include
                      if (input_brands.trim() === "") return;
                      if (!productData.variation.includes(input_brands)) {
                        setProductData((prev) => {
                          const newState = {
                            ...prev,
                            variation: [...prev.variation, input_brands],
                          };
                          return newState;
                        });
                      }
                      setInput_brands("");
                    }}
                    className="h-[40px] bg-[#4f46e5] flex-center text-white font-medium rounded-full px-5 cursor-pointer ml-4"
                  >
                    Add
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 min-h-[56px] rounded-[0.25rem] flex-1 mb-4 ">
                <div className="flex flex-wrap p-2 gap-2">
                  {productData.variation.map((x) => {
                    return (
                      <div className="a border-indigo-700 border px-4 py-2 rounded-[0.25rem] bg-indigo-700 text-white font-medium flex items-center">
                        {x}
                        <div
                          onClick={() => {
                            setProductData((prev) => {
                              const newState = {
                                ...prev,
                                variation: prev.variation.filter(
                                  (y) => y !== x
                                ),
                              };
                              return newState;
                            });
                          }}
                          className="group cursor-pointer w-[24px] h-[24px] ml-4"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#fff"
                            className="group-hover:hidden block "
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#fff"
                            className="group-hover:block hidden "
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                          </svg>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-8 py-4 flex justify-between">
        <div
          onClick={() => oc_close()}
          className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition  hover:bg-[#e5e9ef] cursor-pointer"
        >
          Cancel
        </div>
        <div
          onClick={async () => {
            await AddProduct(productData);
            oc_close();
          }}
          className="h-[40px] bg-[#4f46e5] flex-center text-white font-medium rounded-full px-5 cursor-pointer"
        >
          Create
        </div>
      </div>
    </div>
  );
}

function PupCategory({ meta, oc_close }) {
  const [category_data, setCategory_data] = useState("");
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [sel_category, setSel_category] = useState("");
  const [subCatData, setSubCatData] = useState([]);
  useEffect(() => {
    if (sel_category) {
      setSubCatData(
        _.cloneDeep(meta?.category?.[sel_category]?.sub_category || [])
      );
    } else {
      setSubCatData([]);
    }
  }, [sel_category, meta]);

  return (
    <div className="fixed z-20 inset-0 bg-[#00000052] flex-center">
      <div className="w-[50rem] max-w-[75vw] min-h-[80%] max-h-[80%] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="min-h-0 overflow-y-auto w-full flex flex-col  flex-auto items-center sm:items-start pt-8 shadow-md">
          <div className="w-full pr-[2rem] px-8 flex">
            <div className="flex flex-col flex-1">
              <div className=" text-[18px] leading-6 font-bold">
                Category Manager
              </div>
              <div className="text-[14px] text-gray-500">
                {Object.keys(meta?.category || {}).length} Categories{" "}
                <span className="mx-2">|</span>{" "}
                {Object.values(meta?.category || {}).reduce(
                  (p, c) =>
                    c?.sub_category?.length || false
                      ? p + c.sub_category.length
                      : p,
                  0
                )}{" "}
                Sub Categories
              </div>
            </div>

            <div
              onClick={() => setShowNewCategory(true)}
              className="bg-[#4f46e5] text-white px-[20px] h-[40px] rounded-full cursor-pointer flex items-center ml-4"
            >
              <span className="material-symbols-rounded">add</span>
              <div className="ml-2 mr-1 font-medium">Add</div>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto min-h-0 w-full mt-4 flex flex-col border border-[#e2e8f0] ">
            {showNewCategory && (
              <div className="shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.1)_0px_4px_6px_-4px]">
                <div className="px-8 py-3 border-b border-[#e2e8f0] flex flex-col">
                  <div className="text-lg font-semibold mb-4 my-4">
                    New Category
                  </div>
                  <div className="grid grid-cols-[1fr] gap-8">
                    <div className="flex flex-col">
                      <Input
                        value={category_data}
                        onChange={(e) => {
                          setCategory_data(e.target.value);
                        }}
                        text="Category Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-8 py-4 flex justify-between">
                  <div
                    onClick={async () => {
                      setCategory_data("");
                      setShowNewCategory(false);
                    }}
                    className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition  hover:bg-[#e5e9ef] cursor-pointer"
                  >
                    Cancel
                  </div>
                  <div
                    onClick={async () => {
                      await AddCategory(category_data);
                      setCategory_data("");
                      setShowNewCategory(false);
                    }}
                    className="h-[40px] bg-[#4f46e5] flex-center text-white font-medium rounded-full px-5 cursor-pointer"
                  >
                    Create
                  </div>
                </div>
              </div>
            )}

            {!showNewCategory &&
              Object.keys(meta?.category || {}).length === 0 && (
                <div className="flex-1 flex-center">No Category Yet</div>
              )}
            {Object.entries(meta?.category || {}).map(
              ([category_id, category_obj]) => {
                const newData = JSON.stringify(subCatData);
                const oldData = JSON.stringify(
                  meta?.category?.[sel_category]?.sub_category ?? []
                );

                const canUpdate = newData !== oldData;
                return (
                  <React.Fragment key={category_id}>
                    <div
                      className={`grid grid-cols-[1fr_50px] flex-none h-[50px] px-8 border-b relative ${
                        sel_category && sel_category === category_id
                          ? "bg-gray-50 font-semibold"
                          : ""
                      }`}
                    >
                      <div
                        className={`flex items-center ${
                          sel_category &&
                          sel_category !== category_id &&
                          "opacity-25"
                        }`}
                      >
                        <div className="truncate capitalize">{category_id}</div>
                      </div>
                      <div className="truncate capitalize flex-center">
                        <div
                          onClick={() => {
                            setSel_category((prev) =>
                              prev === category_id ? "" : category_id
                            );
                          }}
                          className="text-[#64748b] border border-[#cbd5e1] bg-white rounded-full px-2 h-7 flex-center cursor-pointer hover:bg-[#94a3b833]"
                        >
                          <span className="material-symbols-rounded">
                            {sel_category === category_id
                              ? "keyboard_arrow_up"
                              : "keyboard_arrow_down"}
                          </span>
                        </div>
                      </div>
                    </div>

                    {sel_category === category_id && (
                      <div className="shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.1)_0px_4px_6px_-4px]">
                        <div className=" border-b border-[#e2e8f0] flex flex-col">
                          <div className="px-8 text-lg font-semibold mb-4 my-4 flex items-center">
                            Sub Category
                            <div
                              onClick={() => {
                                setSubCatData((prev) => {
                                  return [
                                    ...prev,
                                    {
                                      isNew: true,
                                      name: "",
                                      url: "",
                                      createdAt: Date.now(),
                                    },
                                  ];
                                });
                              }}
                              className="w-[40px] h-[40px] cursor-pointer flex-center"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#4f46e5"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
                              </svg>
                            </div>
                          </div>
                          <div className=" px-8 pt-3 grid grid-cols-[1fr] gap-2 border-t border-[#e2e8f0]">
                            {subCatData.length === 0 && (
                              <div className="-mt-3 flex-1 flex-center h-[4rem]">
                                No Sub-Category Yet
                              </div>
                            )}
                            {subCatData.map((x, i) => {
                              return (
                                <div
                                  key={i}
                                  className="grid grid-cols-[4.5rem_1fr_1fr] gap-4 "
                                >
                                  <div className="relative pt-[100%] h-0 bg-gray-200 overflow-hidden rounded-[0.25rem]">
                                    {x.url && (
                                      <img
                                        src={x.url || ""}
                                        className="absolute inset-0 w-full h-full object-cover"
                                      />
                                    )}
                                  </div>
                                  <Input
                                    // disabled={!x.isNew}
                                    value={x.name}
                                    onChange={(e) => {
                                      setSubCatData((prev) => {
                                        const newState = [...prev];
                                        newState[i].name = e.target.value;
                                        return newState;
                                      });
                                    }}
                                    text="Category Name"
                                  />

                                  <InputFileLoader
                                    sel_category={sel_category}
                                    text="Select JPEG"
                                    url={x.url ?? ""}
                                    required
                                    setURL={(url) => {
                                      setSubCatData((prev) => {
                                        const newState = [...prev];
                                        newState[i].url = url;
                                        return newState;
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="px-8 py-4 flex justify-between">
                          <div
                            onClick={() => {
                              setSel_category("");
                            }}
                            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition  hover:bg-[#e5e9ef] cursor-pointer"
                          >
                            Cancel
                          </div>

                          <div
                            onClick={async () => {
                              await UpdateSubCategory(sel_category, subCatData);
                              setSel_category("");
                            }}
                            className={`h-[40px] bg-[#4f46e5] flex-center text-white font-medium rounded-full px-5 cursor-pointer ${
                              !canUpdate && " opacity-75"
                            }`}
                          >
                            Update
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              }
            )}
          </div>
        </div>

        <div className="flex items-center justify-center sm:justify-between px-6 py-4 space-x-3 bg-[#f8fafc]">
          <div className="text-sm"></div>
          <div
            onClick={oc_close}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition  hover:bg-[#e5e9ef] cursor-pointer"
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
}

function Button({ onClick, icon, className }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={
        className +
        (className.includes("absolute") ? "" : " relative ") +
        " group flex-center w-10 h-10 text-[#94a3b8] cursor-pointer rounded-full"
      }
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#94a3b833] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">{icon}</span>
    </div>
  );
}

function StatusDropdown({
  text,
  required,
  value,
  onChange,
  verify,
  changes,
  options,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <select
          value={value}
          onChange={onChange}
          className="w-full outline-none bg-transparent"
        >
          <option value={""}>Unpaid</option>
          <option value={"pending"}>Pending</option>
          <option value={"complete"}>Paid</option>
        </select>
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function Dropdown({
  text,
  required,
  value,
  onChange,
  verify,
  changes,
  options,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <select
          value={value}
          onChange={onChange}
          className="w-full outline-none bg-transparent capitalize"
        >
          <option className="hidden">-</option>
          {options &&
            options.map((x) => {
              return (
                <option key={x} value={x}>
                  {x}
                </option>
              );
            })}
        </select>
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function InputTextArea({
  text,
  required,
  value,
  changes,
  onChange,
  verify,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);

  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <textarea
          disabled={disabled}
          cols={4}
          value={value}
          onChange={onChange}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-transparent caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function InputFileLoader({
  text,
  required,
  value,
  onChange,
  verify,
  sel_category,
  url,
  setURL,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [reupload, setReupload] = useState(false);

  const [progress, setProgress] = useState(0);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div className="absolute left-0 top-[-25px] font-medium">
        {text} {required && <span className="a">*</span>}
      </div>
      <div
        className={`overflow-hidden rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 relative ${
          hasError ? `border-[#dc2626]` : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div
          className="absolute bg-gray-100 top-0 left-0 h-full"
          style={{ width: `${progress}` }}
        ></div>

        <input
          value={value}
          onChange={async (e) => {
            setIsLoading(true);
            const url = await AddCategoryImage(
              sel_category,
              e.target.files[0],
              (p) => {
                setProgress(p);
              }
            );
            setProgress(0);
            setURL(url);
            setIsLoading(false);
          }}
          type="file"
          accept={["image/jpg", "image/jpeg", "image/webp"]}
          className={`flex-1 w-full h-[46px] py-[10px] leading-[10px] my-auto  appearance-none outline-none bg-transparent caret-[${blue_theme}] z-10 ${
            isLoading && "invisible"
          }`}
        />

        {/* {url && !reupload && (
          <div className="absolute inset-0 z-40 w-full h-full bg-white text-white flex-center">
            Reupload
          </div>
        )} */}

        {isLoading && (
          <div className="absolute inset-0 flex items-center pl-4">
            Loading...
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

function Input({
  text,
  type = "text",
  required,
  value,
  onChange,
  verify,
  disabled = false,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  return (
    <div className="relative w-full mt-6 mb-4">
      <div
        className={`min-h-[48px] h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 bg-white ${
          disabled && "bg-gray-100"
        } ${
          hasError ? `border-[#dc2626]` : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          disabled={disabled}
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none caret-[${blue_theme}] `}
        />

        {type === "password" && (
          <div className="w-10 h-10 flex-none ml-1 mr-[-10px]">
            <Button
              icon={showPass ? "visibility_off" : "visibility"}
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}

const star_full = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="24"
    width="24"
    fill="#fbd231"
  >
    <path d="M15.35 41.2q-.85.7-1.775.05-.925-.65-.575-1.7l3.3-10.75-8.5-6.1q-.9-.65-.575-1.675Q7.55 20 8.65 20H19.2l3.35-11.2q.15-.55.575-.825Q23.55 7.7 24 7.7t.875.275q.425.275.575.825L28.8 20h10.55q1.1 0 1.425 1.025.325 1.025-.575 1.675l-8.5 6.1L35 39.55q.35 1.05-.575 1.7-.925.65-1.775-.05L24 34.6Z" />
  </svg>
);

const star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="24"
    width="24"
    fill="#00000088"
  >
    <path d="M24 25.15ZM13.5 42.6q-.45.35-.875.025-.425-.325-.275-.875L16.3 28.8 5.9 21.35q-.45-.3-.275-.825Q5.8 20 6.35 20H19.2l4.1-13.6q.1-.25.275-.4.175-.15.425-.15t.425.15q.175.15.275.4L28.8 20h12.85q.55 0 .725.525.175.525-.275.825L31.7 28.8l3.95 12.95q.15.55-.275.875-.425.325-.875-.025l-10.5-8Zm3.65-6.75 6.85-5.2 6.85 5.2-2.75-8.65 6.3-4.1h-7.55L24 14.45l-2.85 8.65H13.6l6.3 4.1Z" />
  </svg>
);
