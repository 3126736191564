import _ from "lodash";
import { ReadInquiry, UseDB, UseInquiry } from "mFirebase/useAdminEvent";
import moment from "moment";
import React, { useState } from "react";

export default function OverallTab() {
  const db_presence = UseDB("presence");
  const db_analytic = UseDB("analytic");

  return (
    <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
      <div className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-6 sm:py-12 md:px-8 bg-white">
        <div className="relative flex flex-col">
          <div className="text-[32px] leading-[32px] font-extrabold">
            Site Info
          </div>
          <div className="text-[14px] mt-[3px] text-[#64748b] font-medium"></div>
        </div>
      </div>
      <div className="flex-1 p-12">
        <div className="flex gap-8">
          <div className="flex flex-col gap-6 bg-white shadow rounded-[0.5rem] p-10">
            <div className="text-xl font-semibold text-gray-900">
              Current Visitor
            </div>
            <div className="a">
              <div className="text-center text-5xl text-gray-700">
                {_.keys(db_presence).length}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 bg-white shadow rounded-[0.5rem] p-10">
            <div className="text-xl font-semibold text-gray-900">
              Product View
            </div>
            <div className="flex flex-col gap-2">
              {_.entries(db_analytic)
                .slice(0, 10)
                .map(([key, val]) => {
                  return (
                    <div className="rounded-[0.25rem] flex items-center bg-white shadow border px-4 h-[40px] min-w-[300px]">
                      <div className="flex-1 font-semibold">{key}</div>
                      <div className="a">{val}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
