import { useFirebaseApp } from "./useFirebaseApp";
import { useState, useEffect } from "react";
import { singletonHook, SingletonHooksContainer } from "react-singleton-hook";
import {
  createUserWithEmailAndPassword,
  EmailAuthCredential,
  getAuth,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  onValue,
  get,
  runTransaction,
  update,
  push,
  query,
  limitToLast,
  serverTimestamp,
  increment,
  child,
  OnDisconnect,
  onDisconnect,
} from "firebase/database";
import { UploadSingleFile } from "./useFireStorage";

import {
  useList,
  useListKeys,
  useListVals,
  useObject,
} from "react-firebase-hooks/database";
var _ = require("lodash");

let app = null;
let db = null;

export function UseCatalog() {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    const db = getDatabase();
    const r = ref(db, "catalog");

    const unsub = onValue(
      r,
      (snap) => {
        setState(snap.val());
      },
      (e) => {
        console.log("error", e);
        return { error: e };
      }
    );
    return () => {
      unsub();
    };
  }, [app]);

  return state;
}

export async function AddCatalogBrand(brand_name) {
  const db = getDatabase();
  const r = ref(db, `catalog/brand`);
  try {
    await push(r, brand_name);
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function DelCatalogBrand({ brand_id, brand_name }) {
  const db = getDatabase();
  const r1 = ref(db, `catalog/brand/${brand_id}`);
  const r2 = ref(db, `catalog/file/${brand_name}`);
  try {
    await set(r1, null);
    await set(r2, null);
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function AddCatalog(brand_name, data, progressCB) {
  const db = getDatabase();
  const r = ref(db, `catalog/file/${brand_name}`);
  try {
    const res_url = await UploadSingleFile(
      `catalog/${brand_name}/${data.file_name}.pdf`,
      data.file,
      progressCB
    );
    await push(r, {
      file_name: data.file_name,
      url: res_url,
      createdAt: Date.now(),
    });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function DelCatalog(sel_brand, catalog_id) {
  const db = getDatabase();
  const r = ref(db, `catalog/file/${sel_brand}/${catalog_id}`);
  try {
    await set(r, null);
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export function UseCareer() {
  const [state, setState] = useState(null);
  app = useFirebaseApp();

  useEffect(() => {
    if (!app) return;
    const db = getDatabase();
    const r = ref(db, "career");
    const unsub = onValue(
      r,
      (snap) => {
        setState(snap.val());
      },
      (e) => {
        console.log("error", e);
        return { error: e };
      }
    );
    return () => {
      unsub();
    };
  }, [app]);

  return state;
}

export async function AddCareer(data) {
  const db = getDatabase();
  const r = ref(db, `career/position`);
  try {
    await push(r, {
      ...data,
      visible: false,
      createdAt: Date.now(),
    });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function DelCareer(career_id) {
  const db = getDatabase();
  const r = ref(db, `career/position/${career_id}`);
  try {
    await set(r, null);
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function ToggleCareer(career_id, visible) {
  const db = getDatabase();
  const r = ref(db, `career/position/${career_id}`);
  try {
    await update(r, { visible });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function ReadApplication(application_id) {
  const db = getDatabase();
  const r = ref(db, `career/application/${application_id}`);
  try {
    await update(r, { readAt: Date.now() });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export function UseInquiry() {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  const db = getDatabase();
  const r = ref(db, "inquiry");
  useEffect(() => {
    const unsub = onValue(
      r,
      (snap) => {
        setState(snap.val());
      },
      (e) => {
        console.log("error", e);
        return { error: e };
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return state;
}
export async function ReadInquiry(inquiry_id) {
  const db = getDatabase();
  const r = ref(db, `inquiry/${inquiry_id}`);
  try {
    await update(r, { readAt: Date.now() });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export function UseQuotation() {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  const db = getDatabase();
  const r = ref(db, "quotation");
  useEffect(() => {
    const unsub = onValue(
      r,
      (snap) => {
        setState(snap.val());
      },
      (e) => {
        console.log("error", e);
        return { error: e };
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return state;
}
export async function ReadQuotation(quotation_id) {
  const db = getDatabase();
  const r = ref(db, `quotation/${quotation_id}`);
  try {
    await update(r, { readAt: Date.now() });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export function UseSubscription() {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  const db = getDatabase();
  const r = ref(db, "subscription");
  useEffect(() => {
    const unsub = onValue(
      r,
      (snap) => {
        setState(snap.val());
      },
      (e) => {
        console.log("error", e);
        return { error: e };
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return state;
}

export function UseProduct() {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  const db = getDatabase();
  const r = ref(db, "product");
  useEffect(() => {
    const unsub = onValue(
      r,
      (snap) => {
        setState(snap.val());
      },
      (e) => {
        console.log("error", e);
        return { error: e };
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return state;
}

export async function AddProduct(data) {
  return new Promise(async function (resolve, reject) {
    // Create a root reference
    try {
      const db = getDatabase();
      const r = ref(db, `product`);

      runTransaction(r, (productRef) => {
        if (!productRef) return productRef;
        productRef._meta.count = ++productRef._meta.count || 1;
        const push_id = push(ref(db));

        if (!productRef.list) {
          productRef.list = {};
        }
        productRef.list[push_id.key] = { ...data, createdAt: Date.now() };
        // console.log(productRef.list[push_id.key]);
        console.log("productRef,", productRef);
        return productRef;
      });
      // console.log("success");
      resolve(true);
    } catch (e) {
      console.log("error", e);
      reject({ error: e });
    }
  });
}

export async function DelProduct(product_id) {
  return new Promise(function (resolve, reject) {
    // Create a root reference
    try {
      const db = getDatabase();
      const r = ref(db, `product`);

      runTransaction(r, (productRef) => {
        productRef._meta.count = Math.max(--productRef._meta.count || 0, 0);

        if (!productRef.list) {
          productRef.list = {};
          return productRef;
        }
        delete productRef.list[product_id];
        return productRef;
      });
      resolve({});
    } catch (e) {
      console.log("error", e);
      reject({ error: e });
    }
  });
}

export async function UpdateProduct(category_id, data) {
  const db = getDatabase();
  const r = ref(db, `product/_meta/category/${category_id}/sub_category`);
  const cleaned = data.map((x) => _.omit(x, ["isNew"]));
  try {
    await set(r, cleaned);
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function AddCategory(category_name) {
  const db = getDatabase();
  const r = ref(db, `product/_meta/category/${category_name}`);
  try {
    await set(r, {
      createdAt: Date.now(),
    });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}
export async function AddSubCategory(category_name, sub_arr) {
  const db = getDatabase();
  const r = ref(db, `product/_meta/category/${category_name}`);
  try {
    await update(r, {
      sub_category: Date.now(),
    });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function AddCategoryImage(category, file, progressCB) {
  try {
    const newID = push(ref(getDatabase()));
    const res_url = await UploadSingleFile(
      `category/${category}/${newID.key}`,
      file,
      progressCB
    );

    return res_url;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function UpdateSubCategory(category_id, data) {
  const db = getDatabase();
  const r = ref(db, `product/_meta/category/${category_id}/sub_category`);
  const cleaned = data.map((x) => _.omit(x, ["isNew"]));
  try {
    await set(r, cleaned);
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function ReadDB(path) {
  const db = getDatabase();
  const r = ref(db, path);

  const res = await get(r);
  if (res.error) return { error: res.error };
  else return res.val();
}

export function UseDB(path) {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    const db = getDatabase();
    const r = ref(db, path);

    const unsub = onValue(
      r,
      (snap) => {
        setState(snap.val());
      },
      (e) => {
        console.log("error", e);
        return { error: e };
      }
    );
    return () => {
      unsub();
    };
  }, [app]);

  return state;
}

export function GenID() {
  const db = getDatabase();
  return push(ref(db)).key;
}

export async function UpdateDB(path, data) {
  const db = getDatabase();
  const r = ref(db, path);
  try {
    const res = await update(r, data);
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

export async function UsePresence() {
  const app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    const db = getDatabase();
    const id = GenID();
    const r = ref(db, `presence/${id}`);
    set(r, true);
    onDisconnect(r).set(null);
  }, [app]);
}
