import React, { useState } from "react";
import { FireSignOut, useFireUser } from "mFirebase/useFireUser";

import { TAB_NAME } from "./Info";

import { useImages } from "component/hook/useImages";

const TAB_ICON = [
  "library_add_check",
  "request_quote",
  "live_help",
  // "group",
  "mail",
  "shopping_basket",
  "library_books",
  "work",
];

export default function Sidebar({ showSidebar, currentTab, setCurrentTab }) {
  const auth = useFireUser();
  const images = useImages();
  const [showFloatProfile, setShowFloatProfile] = useState(false);

  const name = auth?.user?.displayName || "Sheng Yi";
  const email = auth?.user?.email;
  return (
    <div
      className={`h-full top-0 w-[280px] overflow-auto flex-none min-h-screen max-h-screen bg-[#0f172a] transition-all duration-300 pb-[40px] ${
        showSidebar ? "ml-0" : "ml-[-280px]"
      }`}
    >
      <div className="flex items-center w-full p-4 pl-6 h-[72px]">
        <img src={images["logo.png"]} className="w-8" />

        <div className="flex items-center ml-auto">
          <Button
            onClick={() => {
              setShowFloatProfile((prev) => !prev);
            }}
          />
        </div>
      </div>

      <div className="flex flex-col items-center w-full p-4">
        <div className="w-24 h-24">
          <div className=" bg-indigo-300 rounded-full w-full h-full">
            <div className="text-[50px] flex-center w-full h-full">
              {name[0]}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full mt-6">
          <div className="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium text-white">
            {name}
          </div>
          <div className="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-[#94a3b8]">
            {email}
          </div>
        </div>
      </div>

      <NavGroup {...{ currentTab, setCurrentTab }} />
      {showFloatProfile && <FloatMenu {...{ setShowFloatProfile }} />}
    </div>
  );
}

function NavGroup({ currentTab, setCurrentTab }) {
  return (
    <div className="mt-[24px]">
      {TAB_NAME.map((x, i, arr) => {
        const prev_cat = arr[i - 1]?.split("@@")[0] || "";
        const curr_cat = x.split("@@")[0];
        const curr_name = x.split("@@")[1];
        const cat_arr = curr_cat.split("##");
        const same_cat = prev_cat === curr_cat;
        return (
          <React.Fragment key={x}>
            {!same_cat && <NavTitle title={cat_arr[0]} desc={cat_arr[1]} />}
            <NavItem
              onClick={() => {
                setCurrentTab(x);
              }}
              text={curr_name}
              active={currentTab === x}
              icon={TAB_ICON[i]}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

function NavTitle({ title, desc }) {
  return (
    <div className="mx-[12px] py-[10px] px-[16px]">
      <div className="text-[#818cf8] text-[12px] uppercase font-semibold tracking-wider leading-5">
        <span>{title.replaceAll("_", " ")}</span>
      </div>
      <div className="font-medium text-white opacity-50 text-[11px] capitalize">
        <span>{desc.replaceAll("_", " ")} </span>
      </div>
    </div>
  );
}
function NavItem({ onClick, icon, text, active }) {
  return (
    <div onClick={onClick} className="mb-1 mx-3 text-white cursor-pointer">
      <div
        className={`py-[10px] px-4  h-[44px] rounded-[6px] flex items-center ${
          active && "bg-[#ffffff1f]"
        }`}
      >
        <div className="mr-4 w-6 h-6 ">
          <span className="material-symbols-rounded">{icon}</span>
        </div>
        <div className="text-[13px] capitalize font-medium leading-5">
          {text.replaceAll("_", " ")}
        </div>
      </div>
    </div>
  );
}

function Button({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="group relative flex-center w-10 h-10 text-[#94a3b8] cursor-pointer  rounded-full"
    >
      <div className="opacity-0 group-hover:opacity-100 transition absolute bg-[#ffffff1f] w-full h-full rounded-full"></div>
      <span className="z-10 material-symbols-rounded">account_circle</span>
    </div>
  );
}

function FloatMenu({ setShowFloatProfile }) {
  const auth = useFireUser();
  return (
    <div className="fixed z-30 w-screen h-screen left-0 top-0 flex ">
      <div
        onClick={() => {
          setShowFloatProfile(false);
        }}
        className="absolute bg-transparent opacity-20 w-full h-full"
      ></div>
      <div className="absolute bg-white w-[264px] top-[56px] left-[225px] py-2 flex flex-col rounded shadow-[rgba(0,0,0,0.2)_0px_2px_4px_-1px,rgba(0,0,0,0.14)_0px_4px_5px_0px,rgba(0,0,0,0.12)_0px_1px_10px_0px]">
        <div className="h-12 px-4 flex flex-col hover:bg-[#94a3b81f]">
          <div className="">Signed in as</div>
          <div className="">{auth?.user?.email}</div>
        </div>

        <div className="my-2 h-px w-full bg-[#e2e8f0]"></div>

        <div
          onClick={() => {
            FireSignOut();
          }}
          className="h-12 px-4 flex items-center hover:bg-[#94a3b81f] cursor-pointer"
        >
          <span className="material-symbols-rounded">logout</span>
          <div className="ml-3">Sign out</div>
        </div>
      </div>
    </div>
  );
}
