import { useState } from "react";
import useInterval from "./hook/useInterval";
import moment from "moment";

export default function CurrentTime() {
  const [time, setTime] = useState("");
  useInterval(() => {
    setTime(moment().format("HH:mm:ss"));
  }, 1000);
  return time;
}
