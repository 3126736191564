import {
  AddCareer,
  DelCareer,
  ReadApplication,
  ToggleCareer,
  UseCareer,
} from "mFirebase/useAdminEvent";
import moment from "moment";
import React, { useEffect, useState } from "react";
export default function CareerTab(second) {
  const [showNew, setShowNew] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showApplicant, setShowApplicant] = useState(false);
  const [sel_career, setSel_career] = useState("");
  const db_career = UseCareer();
  const [application_list, setapplication_list] = useState([]);
  useEffect(() => {
    const a = Object.entries(db_career?.application || {}).map(([key, val]) => {
      return { ...val, id: key };
    });
    setapplication_list(a);
  }, [db_career]);

  return (
    <React.Fragment>
      {showApplicant && (
        <PupApplicant
          career_data={{ ...db_career?.position?.[sel_career], id: sel_career }}
          applicant_list={application_list.filter(
            (x) => x.career_id === sel_career
          )}
          oc_cancel={() => setShowApplicant(false)}
          oc_confirm={async () => {
            // await DelCareer(sel_career);
            setShowApplicant(false);
          }}
        />
      )}
      {showConfirmation && (
        <PupConfirmation
          oc_cancel={() => setShowConfirmation(false)}
          oc_confirm={async () => {
            await DelCareer(sel_career);
            setShowConfirmation(false);
          }}
          title="Remove position"
          desc="Are you sure you want to remove this position permanently?"
        />
      )}
      {showNew && <PupAddBrand oc_cancel={() => setShowNew(false)} />}
      {/* ===== HEADER ===== */}
      <div className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-6 sm:py-12 md:px-8 bg-white">
        <div className="relative flex flex-col">
          <div className="text-[32px] leading-[32px] font-extrabold">
            Career Manager
          </div>
          <div className="text-[14px] mt-[3px] text-[#64748b] font-medium">
            {Object.values(db_career?.position || {}).reduce((p, c) => {
              const a = c.visible ? 1 : 0;
              return p + a;
            }, 0)}{" "}
            Position
          </div>
        </div>
        <div className="flex items-center">
          <div
            onClick={() => {
              setShowNew(true);
            }}
            className="bg-[#4f46e5] text-white px-[20px] h-[40px] rounded-full cursor-pointer flex items-center ml-4"
          >
            <span className="material-symbols-rounded">add</span>
            <div className="ml-2 mr-1 font-medium">Add</div>
          </div>
        </div>
      </div>

      <div className=" p-6 md:p-8 space-y-8 flex-1 overflow-y-auto min-h-0">
        <div className="grid grid-cols-2 mx-4">
          {Object.entries(db_career?.position ?? {}).map(
            ([career_id, career_obj]) => {
              const unread_list = application_list.filter((x) => {
                return x.career_id === career_id && !x.readAt;
              });
              return (
                <Card
                  key={career_id}
                  data={career_obj}
                  unread_list={unread_list}
                  oc_list={() => {
                    setSel_career(career_id);
                    setShowApplicant(true);
                  }}
                  oc_delete={() => {
                    setSel_career(career_id);
                    setShowConfirmation(true);
                  }}
                  oc_visible={() => {
                    ToggleCareer(career_id, !career_obj.visible);
                  }}
                />
              );
            }
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

function Card({ data, oc_delete, oc_visible, oc_list, unread_list }) {
  const hasNew = unread_list?.length > 0 || false;
  return (
    <div
      className={`m-4 bg-white flex flex-col  rounded-[1rem] p-8 pb-4 filter-listing  ${
        hasNew && "border-indigo-700 border-t-4"
      }`}
      style={{
        boxShadow: "0 1px 3px 0 #aaa, 0 1px 2px -1px #aaa",
      }}
    >
      {/**/}
      <div className="text-2xl font-semibold leading-tight">{data.title}</div>

      <div className="mt-6 flex-1">
        <div className="text-gray-600">Requirement: </div>
        <div className="pl-[0.5rem] pt-[0.3rem]">
          {data.requirement.split("\n").map((x, i) => {
            return (
              <div key={i} className="a">
                {x}
              </div>
            );
          })}
        </div>

        <div className="text-gray-600 mt-[1rem]">Responsibility: </div>
        <div className="pl-[0.5rem] pt-[0.3rem]">
          {data.responsibility.split("\n").map((x, i) => {
            return (
              <div key={i} className="a">
                {x}
              </div>
            );
          })}
        </div>
      </div>

      {!!hasNew && (
        <div
          onClick={oc_list}
          className="h-[40px] flex items-center px-4 border border-indigo-600 bg-[#ededfc] rounded-[0.5em] text-sm text-indigo-700 font-light mt-[2rem] cursor-pointer hover:underline"
        >
          {unread_list?.length} new application
        </div>
      )}
      <div className="h-[40px] flex items-center mt-[1rem]">
        <div className="flex-1 text-sm text-gray-500 font-light">
          {moment(data.createdAt).format("DD/MM/yyyy")}
        </div>
        <div className="flex items-center">
          <ButtonList onClick={oc_list} />
          <ButtonDelete onClick={oc_delete} icon="delete" />
          <ButtonVisible
            onClick={oc_visible}
            icon="visibility"
            value={data.visible}
          />
        </div>
      </div>
    </div>
  );
}

function PupAddBrand({ oc_cancel }) {
  const [newPos, setNewPos] = useState({
    title: "",
    requirement: "",
    responsibility: "",
  });

  async function oc_submit() {
    const res = await AddCareer(newPos);
    if (res.error) {
      console.log("pup error");
    } else {
      oc_cancel();
    }
  }

  return (
    <div className="fixed z-20 inset-0 bg-[#00000052] flex-center">
      <div className="w-[32rem] max-w-[75vw] max-h-[80%] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="min-h-0 overflow-y-auto w-full flex flex-col  flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
          <div className="w-full text-[18px] leading-6 font-bold ng-star-inserted pr-[2rem]">
            Add Position
          </div>

          <div className="w-full mt-4 flex flex-col">
            <Input
              value={newPos.title}
              onChange={(e) => {
                setNewPos((prev) => {
                  const newState = { ...prev };
                  newState.title = e.target.value;
                  return newState;
                });
              }}
              text="Position Title"
              required
            />
            <InputTextArea
              value={newPos.requirement}
              onChange={(e) => {
                setNewPos((prev) => {
                  const newState = { ...prev };
                  newState.requirement = e.target.value;
                  return newState;
                });
              }}
              text="Position Requirement"
              required
            />
            <InputTextArea
              value={newPos.responsibility}
              onChange={(e) => {
                setNewPos((prev) => {
                  const newState = { ...prev };
                  newState.responsibility = e.target.value;
                  return newState;
                });
              }}
              text="Position Responsibility"
              required
            />
          </div>
        </div>

        <div className="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-[#f8fafc]">
          <div
            onClick={oc_cancel}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition hover:bg-[#e5e9ef] cursor-pointer"
          >
            Cancel
          </div>

          <div
            onClick={() => oc_submit()}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-white rounded-full transition bg-indigo-600 hover:bg-[#473fce] cursor-pointer"
          >
            Create
          </div>
        </div>
      </div>
    </div>
  );
}

function ButtonDelete({ onClick, className = "" }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={
        className +
        (className.includes("absolute") ? "" : " relative ") +
        " group flex-center w-10 h-10 text-[#dc2626] cursor-pointer rounded-full"
      }
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#fce9e9] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">delete</span>
    </div>
  );
}
function ButtonVisible({ onClick, value = true, className = "" }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={`group flex-center w-10 h-10 cursor-pointer rounded-full relative ${
        value ? "text-gray-700" : "text-[#94a3b8]"
      }`}
    >
      <div
        className={`bg-transparent transition absolute w-full h-full rounded-full group-hover:bg-[#94a3b833]`}
      ></div>
      <span className="z-10 material-symbols-rounded">
        {value ? "visibility" : "visibility_off"}
      </span>
    </div>
  );
}
function ButtonList({ onClick, className = "" }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={`group flex-center w-10 h-10 cursor-pointer rounded-full relative text-gray-700`}
    >
      <div
        className={`bg-transparent transition absolute w-full h-full rounded-full group-hover:bg-[#94a3b833]`}
      ></div>
      <span className="z-10 material-symbols-rounded">
        format_list_numbered
      </span>
    </div>
  );
}
function Button({ onClick, icon, className }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={
        className +
        (className.includes("absolute") ? "" : " relative ") +
        " group flex-center w-10 h-10 text-[#94a3b8] cursor-pointer rounded-full"
      }
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#94a3b833] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">{icon}</span>
    </div>
  );
}

function PupConfirmation({
  oc_cancel,
  oc_confirm,
  title = "Title",
  desc = "Are you sure you want to remove this content permanently?",
}) {
  return (
    <div className="fixed z-20 inset-0 bg-[#00000052] flex-center">
      <div className="w-[32rem] max-w-[75vw] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="w-full flex flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
          <div className="relative w-10 mr-4">
            <div
              className={`bg-transparent transition bg-[#fce9e9] w-10 h-10 rounded-full flex-center`}
            >
              <svg
                className="w-[24px] h-[24px]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="#dc2626"
                fill="transparent"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-full text-[18px] leading-6 font-medium ng-star-inserted pr-[2rem]">
              {title}
            </div>

            <div className="w-full text-[#64748b] leading-[21px] mt-1 text-[14px]">
              {desc}{" "}
              <span className="font-medium">This action cannot be undone!</span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-[#f8fafc]">
          <div
            onClick={oc_cancel}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition hover:bg-[#e5e9ef] cursor-pointer"
          >
            Cancel
          </div>

          <div
            onClick={oc_confirm}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-white rounded-full transition bg-[#dc2626] hover:bg-[#c62222] cursor-pointer"
          >
            Remove
          </div>
        </div>
      </div>
    </div>
  );
}

function PupApplicant({ data, oc_cancel, applicant_list, career_data }) {
  const [sel_application, setsel_application] = useState("");
  return (
    <div className="fixed z-20 inset-0 bg-[#00000052] flex-center">
      <div className="w-[50rem] max-w-[75vw] min-h-[80%] max-h-[80%] bg-white flex flex-col rounded-[16px] overflow-hidden">
        <div className="min-h-0 overflow-y-auto w-full flex flex-col  flex-auto items-center sm:items-start pt-8 shadow-md">
          <div className="w-full pr-[2rem] px-8">
            <div className=" text-[18px] leading-6 font-bold">
              Position Application
            </div>
            <div className="text-[14px] text-gray-500">{career_data.title}</div>
          </div>
          <div className="flex-1 overflow-y-auto min-h-0 w-full mt-4 flex flex-col border border-[#e2e8f0] ">
            {applicant_list.map((x) => {
              return (
                <React.Fragment key={x.id}>
                  <div className="grid grid-cols-[1fr_2fr_50px] flex-none h-[50px] px-8 border-b relative ">
                    {!x.readAt && (
                      <div className="absolute bg-indigo-700 rounded-full w-2 h-2 right-2 top-2"></div>
                    )}
                    <div className="flex items-center truncate">
                      <div className="truncate">
                        {moment(x.createdAt).format("HH:ss DD/MM/yyyy")}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="truncate capitalize">{x.fullname}</div>
                    </div>
                    <div className="truncate capitalize flex-center">
                      <div
                        onClick={() => {
                          // console.log(x);
                          if (!x.readAt) {
                            ReadApplication(x.id);
                          }
                          if (sel_application !== x.id)
                            setsel_application(x.id);
                          else setsel_application("");
                        }}
                        className="text-[#64748b] border border-[#cbd5e1] bg-white rounded-full px-2 h-7 flex-center cursor-pointer hover:bg-[#94a3b833]"
                      >
                        <span className="material-symbols-rounded">
                          {sel_application === x.id
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </div>
                    </div>
                  </div>

                  {sel_application === x.id && (
                    <div className="p-8 flex flex-col shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.1)_0px_4px_6px_-4px]">
                      <div className="grid grid-cols-2 gap-8">
                        <Input disabled value={x.fullname} text="Full Name" />
                        <Input disabled value={x.contact} text="Contact" />
                      </div>

                      <InputTextArea
                        value={x.description}
                        text="More Info"
                        disabled
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>

        <div className="flex items-center justify-center sm:justify-between px-6 py-4 space-x-3 bg-[#f8fafc]">
          <div className="text-sm">
            Total application: {applicant_list.length}
          </div>
          <div
            onClick={oc_cancel}
            className="h-[40px] min-w-[64px] px-[20px] flex-center text-[14px] font-medium text-[#1e293b] rounded-full border border-[#cbd5e1] transition  hover:bg-[#e5e9ef] cursor-pointer"
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
}

function Input({
  text,
  type = "text",
  required,
  value,
  onChange,
  verify,
  changes,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          disabled={disabled}
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] bg-transparent appearance-none outline-none caret-[${blue_theme}]`}
        />

        {type === "password" && (
          <div className="w-10 h-10 flex-none ml-1 mr-[-10px]">
            <Button
              icon={showPass ? "visibility_off" : "visibility"}
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
function InputTextArea({
  text,
  required,
  value,
  changes,
  onChange,
  verify,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);

  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <textarea
          disabled={disabled}
          cols={4}
          value={value}
          onChange={onChange}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-transparent caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
