import { Link } from "react-router-dom";

import { useHomeImages, useImages } from "component/hook/useImages";
import React, { useEffect, useState } from "react";
import { GenID, UpdateDB, UseDB } from "mFirebase/useAdminEvent";
import _, { filter } from "lodash";
const { search } = require("fast-fuzzy");

export default function Home() {
  const images = useImages();
  const homeImages = useHomeImages();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const db_product = UseDB("/product/list");
  const db_category = UseDB("/product/_meta/category");

  const [predict, setPredict] = useState([]);
  const [filter_text, setfilter_text] = useState("");
  const [filtered_list, setfiltered_list] = useState([]);
  const [showPredict, setshowPredict] = useState(false);
  useEffect(() => {
    const a = search(
      filter_text,
      _.entries(db_product).map(([key, val]) => {
        return { ...val, id: key };
      }),
      {
        keySelector: (obj) => obj.name + obj.measurement,
      }
    );
    setPredict(a.slice(0, 10));
    setfiltered_list(a);
  }, [filter_text, db_product]);

  return (
    <React.Fragment>
      <div className="min-h-screen -mt-[4.5em] flex flex-col">
        {/* ----- SEARCH ----- */}
        <div className="flex-1 container px-6 sm:px-12 mt-[4.5em] mx-auto flex space-x-[1em]  items-center md:max-w-[1200px] 2xl:max-w-[1440px] ">
          {/* ----- SEARCH BAR ----- */}
          <div className="h-[4em] flex-1 flex items-center  my-[7em] lg:my-0">
            {showPredict && (
              <div
                onClick={() => setshowPredict(false)}
                className="left-0 top-0 fixed z-10 w-full h-full"
              ></div>
            )}
            {/* ----- SEARCH BAR ----- */}
            <div className="bg-white relative z-10 h-[4em] flex-1 flex items-center border rounded-[0.4em] px-[2em] shadow-[inset_0px_2px_9px_3px_#00000022]">
              <input
                value={filter_text}
                onChange={(e) => {
                  setfilter_text(e.target.value);
                }}
                onFocus={() => setshowPredict(true)}
                type="text"
                placeholder="search product..."
                className=" bg-transparent appearance-none outline-none  w-full h-full text-[1em] placeholder:text-blue-ci"
              />
              <div
                className={`${
                  showPredict ? "flex" : "hidden"
                } absolute z-10 w-full left-0 top-full flex-col bg-gray-200 border border-gray-300 divide-y-2 rounded-b-[0.5rem] overflow-hidden `}
              >
                {predict.length < 1 && (
                  <div className="py-3 px-6 bg-white cursor-pointer ">
                    <span className="font-semibold capitalize text-center text-gray-500">
                      - No Product found -
                    </span>
                  </div>
                )}
                {predict.map((x) => {
                  return (
                    <Link to={`/product/${x.id}`}>
                      <div
                        onClick={() => {
                          setfilter_text(x.name);
                          setshowPredict(false);
                        }}
                        className="py-3 px-6 bg-white cursor-pointer hover:bg-gray-100"
                      >
                        <span className="font-bold capitalize">
                          {x.sub_category} :{" "}
                        </span>
                        {x.name}{" "}
                        {x.measurement && (
                          <span className="text-[#00000077]">
                            [{x.measurement.toLowerCase()}]
                          </span>
                        )}
                      </div>
                    </Link>
                  );
                })}
              </div>

              <div className="ml-[2em] w-[1.3em] h-[1.3em]">
                <img
                  src={images["icon_search.svg"]}
                  className="w-full h-full cursor-pointer"
                  alt="cart"
                />
              </div>
            </div>
          </div>

          {/* ----- VISIT SHOP ----- */}
          <Link to={`/shop/${filter_text}`}>
            <div className="relative h-[4em] w-[6em] lg:w-auto cursor-pointer">
              <img
                src={images["btn_shop.png"]}
                alt="visit shop"
                className="h-full shadow-[0px_10px_15px_1px_#00000044] rounded-[0.2em] lg:rounded-[0.4em]"
              />

              <div className="absolute top-0 left-0 w-full h-full justify-center items-center font-bold text-white uppercase text-[1em] hidden lg:flex">
                visit shop
              </div>

              <div className="absolute top-0 left-0 w-full h-full justify-center items-center font-bold text-white uppercase text-[1em] flex lg:hidden text-center leading-[1.2em]">
                visit <br /> shop
              </div>
            </div>
          </Link>
        </div>

        {/* _____ SEARCH _____ */}

        {/* ----- COMPANY INFO ----- */}
        <div className="max-full flex flex-col-reverse lg:grid grid-cols-[35fr_65fr]">
          <div className="">
            <img
              src={images["cover_bg.webp"]}
              alt=""
              className="w-full lg:h-full object-cover h-[20em]"
            />
          </div>
          <div className="bg-blue-ci lg:min-h-[60vh]">
            <div className="h-full flex flex-col justify-center text-white py-[8em] lg:py-auto px-8 lg:px-[4em]">
              <div className="text-[2.5em] font-medium leading-[1.2em] hidden lg:block">
                THE LEADING BEARING PRODUCTS <br /> SUPPLIER IN MALAYSIA.
              </div>

              <div className="text-[2.5em] font-medium leading-[1.2em] block lg:hidden">
                THE LEADING BEARING PRODUCTS SUPPLIER <br /> IN MALAYSIA.
              </div>

              <div className="opacity-80 lg:max-w-[80ch] mt-[2em]">
                <div className="leading-[1.7em]">
                  Sheng Yi Bearing Industries Sdn Bhd 's headquartered in Seri
                  Kembangan, Selangor, we have expanded our business nationwide.
                  We specialize in roller & ball bearing, bearing units &
                  plummer, oiley bearing, roller chain, Bando vee-belt, oil
                  seal, mechanical seal, grease & oil, sprocket, pulley, etc.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* _____ COMPANY INFO _____ */}
      </div>

      {/* ----- PRODUCT ----- */}
      <div className="bg-white shadow-[0px_20px_40px_20px_#00000033]">
        {/* ----- TITLE ----- */}
        <div className="flex flex-col py-[0.15em] mt-[12em] lg:mt-[20em] overflow-hidden bg-[#f8f8f8] shadow-[inset_0px_0px_20px_0px_#00000033]">
          <div className=" container  md:max-w-[1200px] 2xl:max-w-[1440px] mx-auto px-6 lg:px-12">
            <div className="flex items-center bg-blue-ci w-[11em] lg:w-[15em] h-[7em] rounded-br-[1.5em] relative">
              <div className="uppercase text-white font-medium text-[1.5em] leading-[1.3em] relative">
                product in <br /> trend
              </div>
              <div className="absolute right-full h-full bg-blue-ci w-[50vw]"></div>
            </div>
          </div>
        </div>

        {/* ----- PRODUCT LIST ----- */}
        <div className="mt-[6em] flex flex-col space-y-[8em] lg:space-y-0 items-center lg:grid grid-cols-5 md:gap-[4em] 2xl:gap-[7em] container mx-auto md:max-w-[1200px] 2xl:max-w-[1440px] px-12">
          {/* ----- ITEM  ----- */}
          <FeaturedItem />
        </div>

        {/* ----- BROWSE PRODUCT ----- */}
        <div className="container mx-auto md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 flex flex-col lg:flex-row items-center mt-[12em] lg:mt-[6em] mb-[6em] lg:mb-[20em]">
          <div className="h-[0.15em] w-3/5 lg:w-auto flex-none lg:flex-1 bg-[#e0e0e0]"></div>
          <Link
            to={"/shop"}
            className="mt-[2em] lg:mt-0 lg:ml-[2em] text-black font-semibold cursor-pointer hover:text-blue-ci"
          >
            browse more product {`>>`}
          </Link>
        </div>
      </div>
      {/* _____ PRODUCT _____ */}

      {/* ----- RELIABLE MANUFACTURER ----- */}
      {/* <div className="mt-[8em] container md:max-w-[1200px] 2xl:max-w-[1440px] px-12 mx-auto flex flex-col mb-[4em]">
        <div className="grid grid-cols-[2fr_1fr]">
          <div className="a">
            <div className="uppercase font-bold text-[2.5em] leading-[1.2em] text-[#404040]">
              RELIABLE PARTNER TO <br /> MANUFACTURER
            </div>
            <div className="text-[#404040] mt-[2.5em] text-[1em] leading-[1.4em] font-light tracking-[0.05em]">
              To be a reliable partner to manufacturer who needs to use our
              bearings. <br />
              Improve the skills of your teams with our training to bearings.
            </div>
          </div>
          <div className="a">
            <div className="relative pt-[100%]">
              <div className="absolute top-0 left-0 w-full h-full">
                <img
                  src={images["img2.jpg"]}
                  className="w-full h-full object-cover rounded-tl-[2em]"
                />
              </div>
              <div className="absolute uppercase flex items-center top-[105%] left-[8%]">
                <span className="arrow-up"></span>
                <span className="ml-[0.7em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                  industrial
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="a">
            <div className="relative pt-[100%]">
              <div className="absolute top-0 left-0 w-full h-full">
                <img
                  src={images["img3.jpg"]}
                  className="w-full h-full object-cover rounded-bl-[2em] rounded-tr-[2em]"
                />
              </div>
              <div className="absolute uppercase flex items-center top-[105%] right-[8%]">
                <span className="arrow-up"></span>
                <span className="ml-[0.7em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                  oil & gas
                </span>
              </div>
            </div>
          </div>
          <div className="a">
            <div className="relative pt-[100%]">
              <div
                style={{
                  background: `url(${images["parallex.jpg"]})`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="absolute top-0 left-0 w-full h-full filter rounded-tl-[2em] overflow-hidden"
              >
                <div
                  style={{
                    boxShadow: "inset 20px 20px 50px 10px #000000aa",
                  }}
                  className="bg-blue-ci w-full h-full opacity-80"
                ></div>
              </div>
            </div>
          </div>
          <div className="a"></div>
        </div>

        <div className="grid grid-cols-3">
          <div className="a"></div>

          <div className="a">
            <div className="relative pt-[100%]">
              <div className="absolute top-0 left-0 w-full h-full">
                <img
                  src={images["img1.jpg"]}
                  className="w-full h-full object-cover rounded-bl-[2em] "
                />
              </div>
              <div className="absolute uppercase flex items-center top-[105%] right-[-1%]">
                <span className="arrow-up"></span>
                <span className="ml-[0.7em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                  automation
                </span>
              </div>
            </div>
          </div>
          <div className="a">
            <div className="relative pt-[100%]">
              <div
                style={{
                  background: `url(${images["parallex.jpg"]})`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="absolute top-0 left-0 w-full h-full filter rounded-br-[2em] overflow-hidden"
              >
                <div
                  style={{
                    boxShadow: "inset 20px 20px 50px 10px #000000aa",
                  }}
                  className="bg-blue-ci w-full h-full opacity-80"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="mt-[16em] container md:max-w-[1200px] 2xl:max-w-[1440px] lg:px-12 px-8 mx-auto  hidden lg:flex flex-col mb-[4em]">
        <div className="grid grid-cols-[2fr_1fr_1fr]">
          <div className="a">
            <div className="uppercase font-bold text-[2.5em] leading-[1.2em] text-[#404040]">
              RELIABLE PARTNER TO <br /> MANUFACTURER
            </div>
            <div className="text-[#404040] mt-[2.5em] text-[1em] leading-[1.4em] font-light tracking-[0.05em]">
              To be a reliable partner to manufacturer who needs to use our
              bearings. Improve the skills of your teams with our training to
              bearings.
            </div>
          </div>
          <div className="a">
            <div className="relative pt-[100%]">
              <div className="absolute top-0 left-0 w-full h-full">
                <img
                  src={homeImages["machine_tool.jpg"]}
                  className="w-full h-full object-cover"
                />
                <div className="absolute uppercase flex items-center top-[105%] left-0">
                  <span className="arrow-up"></span>
                  <span className="ml-[0.5em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                    machine tool
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="a">
            <div className="relative pt-[100%]">
              <div
                style={{
                  background: `url(${images["parallex.webp"]})`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="absolute top-0 left-0 w-full h-full filter overflow-hidden"
              >
                <div
                  style={{
                    boxShadow: "inset 20px 20px 30px 0px #00000077",
                  }}
                  className="bg-blue-ci w-full h-full opacity-80"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-[1fr_1fr_1fr_1fr]">
          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["rubber_glove.jpg"]}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute uppercase flex items-center top-[105%] left-0">
                    <span className="arrow-up"></span>
                    <span className="ml-[0.5em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                      rubber glove
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[50%]"></div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div
                  style={{
                    background: `url(${images["parallex.jpg"]})`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="absolute top-0 left-0 w-full h-full filter overflow-hidden"
                >
                  <div
                    style={{
                      boxShadow: "inset 20px 20px 30px 0px #00000077",
                    }}
                    className="bg-blue-ci w-full h-full opacity-80"
                  ></div>
                </div>
              </div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["palm_oil_mill.jpg"]}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute uppercase flex items-center top-[105%] left-0">
                    <span className="arrow-up"></span>
                    <span className="ml-[0.5em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                      palm oil mill
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[100%]"></div>
            </div>
            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["steel_mill.jpg"]}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute uppercase flex items-center top-[105%] left-0">
                    <span className="arrow-up"></span>
                    <span className="ml-[0.5em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                      steel mill
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="a">
              <div className="relative pt-[50%]"></div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div
                  style={{
                    background: `url(${images["parallex.jpg"]})`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="absolute top-0 left-0 w-full h-full filter overflow-hidden"
                >
                  <div
                    style={{
                      boxShadow: "inset 20px 20px 30px 0px #00000077",
                    }}
                    className="bg-blue-ci w-full h-full opacity-80"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[100%]"></div>
            </div>
            <div className="a">
              <div className="relative pt-[100%]"></div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["automation.jpg"]}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute uppercase flex items-center top-[105%] left-0">
                    <span className="arrow-up"></span>
                    <span className="ml-[0.5em] font-bold tracking-[0.03em] italic text-[#404040] text-[1.3em]">
                      automation
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[8em] container md:max-w-[1200px] 2xl:max-w-[1440px] lg:px-12 px-8 mx-auto  flex lg:hidden flex-col mb-[4em]">
        <div className="a">
          <div className="uppercase font-bold text-[2.5em] leading-[1.2em] text-[#404040]">
            RELIABLE PARTNER TO <br /> MANUFACTURER
          </div>
          <div className="text-[#404040] mt-[2.5em] text-[1em] leading-[1.4em] font-light tracking-[0.05em]">
            To be a reliable partner to manufacturer who needs to use our
            bearings. Improve the skills of your teams with our training to
            bearings.
          </div>
        </div>

        <div className="grid grid-cols-[2fr_1fr_1fr] mt-[4em] -mx-6">
          <div className="a"></div>
          <div className="a">
            <div className="relative pt-[100%]">
              <div className="absolute top-0 left-0 w-full h-full">
                <img
                  src={homeImages["machine_tool.jpg"]}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          <div className="a">
            <div className="relative pt-[100%]">
              <div
                style={{
                  background: `url(${images["parallex.webp"]})`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="absolute top-0 left-0 w-full h-full filter overflow-hidden"
              >
                <div
                  style={{
                    boxShadow: "inset 20px 20px 30px 0px #00000077",
                  }}
                  className="bg-blue-ci w-full h-full opacity-80"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-[1fr_1fr_1fr_1fr] -mx-6">
          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["rubber_glove.jpg"]}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[50%]"></div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div
                  style={{
                    background: `url(${images["parallex.jpg"]})`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="absolute top-0 left-0 w-full h-full filter overflow-hidden"
                >
                  <div
                    style={{
                      boxShadow: "inset 20px 20px 30px 0px #00000077",
                    }}
                    className="bg-blue-ci w-full h-full opacity-80"
                  ></div>
                </div>
              </div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["palm_oil_mill.jpg"]}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[100%]"></div>
            </div>
            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["steel_mill.jpg"]}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>

            <div className="a">
              <div className="relative pt-[50%]"></div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div
                  style={{
                    background: `url(${images["parallex.jpg"]})`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="absolute top-0 left-0 w-full h-full filter overflow-hidden"
                >
                  <div
                    style={{
                      boxShadow: "inset 20px 20px 30px 0px #00000077",
                    }}
                    className="bg-blue-ci w-full h-full opacity-80"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="a">
              <div className="relative pt-[100%]"></div>
            </div>
            <div className="a">
              <div className="relative pt-[100%]"></div>
            </div>

            <div className="a">
              <div className="relative pt-[100%]">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={homeImages["automation.jpg"]}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap lg:hidden mt-[4em] gap-[0.5em]">
          {[
            "steel mill",
            "automation",
            "machine tool",
            "rubber glove",
            "palm oil mill",
          ].map((x) => {
            return (
              <Link
                key={x}
                to="/catalog"
                className="flex items-center capitalize border-[#ddd] border-[2px] p-[0.5em] rounded-[0.5em]"
              >
                <div className="whitespace-nowrap">{x}</div>
                <svg
                  className="ml-[1em]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  height="20"
                  width="20"
                  fill="#aaa"
                >
                  <path d="M24 39.45q-.35 0-.65-.075-.3-.075-.55-.275-2.2-1.45-4.675-2.275Q15.65 36 13 36q-2.1 0-4.125.55T5 38.1q-1.05.55-2.025-.05Q2 37.45 2 36.3V12.2q0-.55.275-1.05t.825-.75q2.3-1.2 4.8-1.8Q10.4 8 13 8q2.9 0 5.675.75T24 11v25.3q2.55-1.65 5.35-2.475Q32.15 33 35 33q1.8 0 3.925.35T43 34.8V9.55q.5.2.975.4t.925.45q.5.3.8.775.3.475.3 1.025v24.1q0 1.15-.975 1.75-.975.6-2.025.05-1.85-1-3.875-1.55T35 36q-2.65 0-5.125.825T25.2 39.1q-.25.2-.55.275-.3.075-.65.075Zm4.25-8.9q-.4.3-.825.125Q27 30.5 27 30V14.3q0-.1.2-.5L38.7 2.3q.35-.35.825-.175T40 2.8V20q0 .1-.25.55ZM21 34.8V12.85q-1.7-.95-3.95-1.4Q14.8 11 13 11q-2.35 0-4.375.5T5 12.8v22q1.75-.85 3.775-1.325T13.05 33q2.2 0 4.2.475T21 34.8Zm0 0V12.85Z" />
                </svg>
              </Link>
            );
          })}
        </div>
      </div>
      {/* _____ RELIABLE MANUFACTURER _____ */}

      <Subscribe />
    </React.Fragment>
  );
}

function Subscribe() {
  const [input_email, setinput_email] = useState("");
  return (
    <React.Fragment>
      <div className="container md:max-w-[1200px] 2xl:max-w-[1440px] px-6 lg:px-12 mx-auto mt-[12em] mb-[4em] hidden lg:block">
        <div className="bg-white flex  items-stretch  pl-[1em] pr-[0.5em] shadow-[0px_0px_30px_0px_#00000033] my-[4em] rounded-[0.5em] py-[0.5em]">
          <div className="flex-1 flex items-center">
            <input
              value={input_email}
              onChange={(e) => {
                setinput_email(e.target.value);
              }}
              className="w-full appearance-none outline-none pl-[1em] h-full"
              type="text"
              placeholder="enter email address to subscribe our newsletter"
            />
          </div>
          <div
            onClick={async () => {
              if (input_email === "") {
                return alert("Email address is required");
              }
              const pushID = GenID();
              const res = await UpdateDB("subscription", {
                pushID: { email: input_email, createdAt: Date.now() },
              });
              if (res.error) {
                return alert(
                  "Something wrong happened, please try again later or contact us"
                );
              }
              setinput_email("");
              return alert("You have subscribe to our newsletter");
            }}
            className="px-[4em] h-[3.5em] bg-blue-ci text-white flex-center rounded-[0.5em] ml-[2em] cursor-pointer"
          >
            Subscribe
          </div>
        </div>
      </div>

      <div className="container md:max-w-[1200px] 2xl:max-w-[1440px] px-8 lg:px-12 mx-auto mt-[12em]  mb-[6em] lg:hidden">
        <div className="font-medium -mx-2">Subscribe to our newsletter</div>
        <div className="mt-[1em] bg-white flex  items-stretch  pl-[1em] pr-[0.5em] shadow-[0px_0px_30px_0px_#00000033] rounded-[0.5em] py-[0.5em]">
          <div className="flex-1 flex items-center">
            <input
              value={input_email}
              onChange={(e) => {
                setinput_email(e.target.value);
              }}
              className="w-full appearance-none outline-none pl-[1em] h-full"
              type="text"
              placeholder="email address"
            />
          </div>
          <div
            onClick={async () => {
              if (input_email === "") {
                return alert("Email address is required");
              }
              const pushID = GenID();
              const res = await UpdateDB("subscription", {
                [pushID]: { email: input_email, createdAt: Date.now() },
              });
              if (res.error) {
                return alert(
                  "Something wrong happened, please try again later or contact us"
                );
              }
              setinput_email("");
              return alert("You have subscribe to our newsletter");
            }}
            className="px-[1em] h-[3.5em] bg-blue-ci text-white flex-center rounded-[0.5em] ml-[2em] cursor-pointer"
          >
            Subscribe
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function FeaturedItem() {
  const db_product = UseDB("/product/list");
  const [featured_list, setfeatured_list] = useState([]);
  useEffect(() => {
    if (!db_product) return;
    const feature = _.entries(db_product).map(([id, val]) => {
      return { ...val, id };
    });
    const feature_arr = feature.filter((x) => x.featured).slice(0, 5);
    setfeatured_list(feature_arr);
  }, [db_product]);

  const db_category = UseDB("/product/_meta/category");
  const [productImage, setproductImage] = useState({});
  useEffect(() => {
    const d = {};
    _.entries(db_category).map(([category_name, category_obj]) => {
      category_obj.sub_category.map((x) => {
        if (!d[category_name]) d[category_name] = {};
        d[category_name][x.name] = x.url;
      });
    });
    setproductImage(d);
  }, [db_category]);

  return featured_list.map((x) => {
    return (
      <div key={x.id} className="relative w-[60%] lg:w-auto">
        {/* ----- IMG  ----- */}
        <div className="pt-[100%] relative">
          <div className="absolute w-full h-full top-0 left-0 overflow-hidden rounded-full border-[#a6a6a6] border-[0.2em] bg-white flex-center">
            <img
              src={productImage[x.category][x.sub_category]}
              className="w-4/5 h-4/5 object-contain "
            />
          </div>
        </div>

        <div className="mt-[4em] flex flex-col">
          <div className="truncate">{x.name}</div>
          <div className="truncate text-[#00000088] capitalize">
            {x.category}
          </div>
        </div>

        <Link to={`/product/${x.id}`}>
          <div className="mt-[1em] capitalize border-[0.15em] border-[#ccc] text-[#636363] font-light rounded-[0.5em] flex-center h-[3.5em] cursor-pointer hover:bg-blue-ci hover:text-white">
            details
          </div>
        </Link>
      </div>
    );
  });
}
