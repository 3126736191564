import { ReadInquiry, UseInquiry } from "mFirebase/useAdminEvent";
import moment from "moment";
import React, { useState } from "react";

export default function InquiryTab() {
  const [showAddBrand, setShowAddBrand] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [sel_inquiry, setSel_inquiry] = useState("");

  const db_inquiry = UseInquiry();

  return (
    <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
      <div className="relative flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-6 sm:py-12 md:px-8 bg-white">
        <div className="relative flex flex-col">
          <div className="text-[32px] leading-[32px] font-extrabold">
            Inquiry Manager
          </div>
          <div className="text-[14px] mt-[3px] text-[#64748b] font-medium">
            {Object.keys(db_inquiry || {}).length} Inquiry
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col border-t border-b border-[#cbd5e1] min-h-0 overflow-y-auto bg-white">
        {!db_inquiry && (
          <div className="flex-1 flex-center">No Inquiry Available</div>
        )}
        {db_inquiry &&
          Object.entries(db_inquiry || {}).map(
            ([inquiry_id, inquiry_obj], i, arr) => {
              console.log(inquiry_obj);
              let sameDay = false;
              let isToday = false;
              const prev = i > 0 ? moment(arr[i - 1][1].createdAt) : "";
              const curr = moment(inquiry_obj.createdAt);
              isToday =
                moment().format("DD/MM/yyyy") === curr.format("DD/MM/yyyy");
              if (i > 0) {
                sameDay =
                  prev.format("DD/MM/yyyy") === curr.format("DD/MM/yyyy");
              }
              // check if same day as prev
              return (
                <React.Fragment key={inquiry_id}>
                  {!sameDay && (
                    <div className="sticky top-0 bg-[#f1f5f9] flex-none h-[50px] px-8 flex items-center text-[16px] font-semibold border-b border-[#cbd5e1]">
                      {isToday ? "Today" : curr.format("DD/MM/yyyy")}
                    </div>
                  )}

                  <div className="flex-none grid grid-cols-[1fr_50px] h-[70px]  px-8 items-center text-[14px] border-b border-[#cbd5e1] relative">
                    {!inquiry_obj.readAt && (
                      <div className="absolute bg-indigo-700 rounded-full w-2 h-2 right-2 top-2"></div>
                    )}
                    <div className="truncate">{inquiry_obj?.description}</div>
                    <div className="truncate capitalize flex-center">
                      <div
                        onClick={() => {
                          // console.log(x);
                          if (!inquiry_obj.readAt) {
                            ReadInquiry(inquiry_id);
                          }
                          if (sel_inquiry !== inquiry_id)
                            setSel_inquiry(inquiry_id);
                          else setSel_inquiry("");
                        }}
                        className="text-[#64748b] border border-[#cbd5e1] bg-white rounded-full px-2 h-7 flex-center cursor-pointer hover:bg-[#94a3b833]"
                      >
                        <span className="material-symbols-rounded">
                          {sel_inquiry === inquiry_id
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </div>
                    </div>
                  </div>

                  {sel_inquiry === inquiry_id && (
                    <div className="p-8 flex flex-col bg-white shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.1)_0px_4px_6px_-4px]">
                      <div className="contents sm:grid grid-cols-2 gap-8">
                        <Input
                          disabled
                          value={inquiry_obj.name}
                          text="Name / Company"
                        />
                        <Input
                          disabled
                          value={inquiry_obj.contact}
                          text="Contact Number"
                        />
                      </div>
                      <Input disabled value={inquiry_obj.email} text="Email" />

                      <InputTextArea
                        value={inquiry_obj.description}
                        text="More Info"
                        disabled
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            }
          )}
      </div>
    </div>
  );
}

function Input({
  text,
  type = "text",
  required,
  value,
  onChange,
  verify,
  changes,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);
  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <input
          disabled={disabled}
          value={value}
          onChange={onChange}
          type={showPass ? "text" : type}
          className={`flex-1 w-full h-full py-[14px] bg-transparent appearance-none outline-none caret-[${blue_theme}]`}
        />

        {type === "password" && (
          <div className="w-10 h-10 flex-none ml-1 mr-[-10px]">
            <Button
              icon={showPass ? "visibility_off" : "visibility"}
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
          </div>
        )}
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
function InputTextArea({
  text,
  required,
  value,
  changes,
  onChange,
  verify,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  const red_warning = "text-[#dc2626]";
  const blue_theme = "#4f46e5";

  const hasError = verify && verify(value);
  const hasChanges = changes && changes(value);

  return (
    <div className="relative w-full mt-6 mb-4 ">
      <div
        className={`min-h-[48px] rounded-md border border-[#cbd5e1] shadow-[rgba(0,0,0,0.05)_0px_1px_2px_0px] px-4 ${
          hasError
            ? `border-[#dc2626]`
            : hasChanges
            ? "border-[#f59e0b] bg-[#fef5e7]"
            : disabled
            ? " bg-[#f8fafc] border-[#f8fafc]"
            : `focus-within:border-[${blue_theme}]`
        } flex items-center`}
      >
        <div className="absolute left-0 top-[-25px] font-medium ">
          {text} {required && <span className="a">*</span>}
        </div>
        <textarea
          disabled={disabled}
          cols={4}
          value={value}
          onChange={onChange}
          className={`flex-1 w-full h-full py-[14px] appearance-none outline-none bg-transparent caret-[${blue_theme}]`}
        />
      </div>
      {verify && (
        <div
          className={`mt-1 text-[12px] leading-none font-medium ${red_warning}`}
        >
          {verify(value)}
        </div>
      )}
    </div>
  );
}
function Button({ onClick, icon, className }) {
  const gray_bg = "#94a3b833";
  return (
    <div
      onClick={onClick}
      className={
        className +
        (className.includes("absolute") ? "" : " relative ") +
        " group flex-center w-10 h-10 text-[#94a3b8] cursor-pointer rounded-full"
      }
    >
      <div
        className={`bg-transparent transition absolute group-hover:bg-[#94a3b833] w-full h-full rounded-full`}
      ></div>
      <span className="z-10 material-symbols-rounded">{icon}</span>
    </div>
  );
}
