import { useFirebaseApp } from "./useFirebaseApp";
import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  onValue,
  get,
  runTransaction,
  update,
  push,
  query,
  limitToLast,
  serverTimestamp,
  increment,
  onDisconnect,
  off,
  onChildAdded,
} from "firebase/database";

import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

var _ = require("lodash");

let app = null;
let db = null;
let auth = null;

// ================= CONNECTION =================
function useConnectionStateImpl() {
  const [state, setState] = useState(false);
  const app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    const db = getDatabase();
    const connectedRef = ref(db, ".info/connected");
    onValue(connectedRef, (snap) => {
      setState(snap.val());
      console.log(snap.val());
      if (snap.val() === true) {
        console.log("connected");
      } else {
        console.log("not connected");
      }
    });
  }, [app]);

  return state;
}
export const useConnectionState = singletonHook(false, useConnectionStateImpl);

// ================= ALL_USER =================
export async function AddCareerApplication(data) {
  const db = getDatabase();
  const r = ref(db, `career/application`);
  try {
    await push(r, {
      ...data,
      createdAt: Date.now(),
    });
    return true;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}

// ================= CUSTOM =================
function ObjectToArray(obj) {
  if (!obj) return [];
  return Object.entries(obj).map(([key, val]) => {
    return {
      id: key,
      ...val,
    };
  });
}
