import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

import UnderConstruction from "page/UnderContruction";
import Home from "page/Home";
import Product from "page/Product";
import ShopPage from "page/ShopPage";
import CatalogPage from "page/CatalogPage";
import CareerPage from "page/CareerPage";
import AboutUsPage from "page/AboutUsPage";

import Header from "page/General/Header";
import Footer from "page/General/Footer";

import Dashboard from "page/Dashboard";
import "./App.css";

import React, { useState, useEffect } from "react";
import {
  AddProduct,
  GenID,
  UpdateDB,
  UsePresence,
  UseProduct,
} from "mFirebase/useAdminEvent";
import { useFirebaseApp } from "mFirebase/useFirebaseApp";
import { increment } from "firebase/database";
import CheckOutPage from "page/CheckOutPage";
import { usePresence } from "framer-motion";

const _ = require("lodash");
function App() {
  useFirebaseApp();
  UsePresence();

  // const [started, setStarted] = useState(false);
  // const [input, setinput] = useState("");
  // const [header, setheader] = useState([
  //   "category",
  //   "sub_category",
  //   "name",
  //   "description",
  //   "measurement",
  //   "variation",
  // ]);
  // const [useHeader, setuseHeader] = useState(false);
  // const [data, setData] = useState("");
  // useEffect(() => {
  //   if (!started) {
  //     const raw = input
  //       .split("\n")
  //       .filter((x) => x.trim() !== "")
  //       .map((row) => row.split("\t"));
  //     // const header = raw[0];
  //     // setheader(header);
  //     // const r = raw.slice(1);

  //     const d = raw.map((x) => {
  //       return _.zipObject(header, x);
  //     });
  //     const dd = d.map((x) => {
  //       x.variation = x.variation.split(",").filter((xx) => xx != "");
  //       return x;
  //     });

  //     setData(dd);
  //   }
  // }, [input]);

  // return (
  //   <div className="p-10">
  //     <div className="a">DEV UPDATE</div>
  //     <textarea
  //       className="border"
  //       value={input}
  //       onChange={(e) => {
  //         setinput(e.target.value);
  //       }}
  //     ></textarea>
  //     <div className="flex">
  //       <div
  //         onClick={async () => {
  //           if (started) return;
  //           setStarted(true);
  //           // execute();
  //           const d = {};
  //           data.map((x) => {
  //             d[`list/${_.uniqueId("b1_")}`] = x;
  //           });
  //           d["/_meta/count"] = increment(data.length);

  //           const res = await UpdateDB("product", d);
  //           if (res.error) return alert(`failed : ${res.error}`);
  //           alert("success");
  //         }}
  //         className={
  //           started
  //             ? "bg-gray-700 text-white px-10 py-3 mt-2"
  //             : "bg-indigo-700 text-white px-10 py-3 mt-2"
  //         }
  //       >
  //         Start
  //       </div>
  //     </div>

  //     <div className="flex flex-col gap-2 mt-10">
  //       {header && (
  //         <div className="grid grid-cols-6 font-bold capitalize">
  //           <div className="a">name</div>
  //           <div className="a">description</div>
  //           <div className="a">measurement</div>
  //           <div className="a">category</div>
  //           <div className="a">sub_category</div>
  //           <div className="a">variation</div>
  //         </div>
  //       )}
  //       {data &&
  //         data.map((x) => {
  //           return (
  //             <div key={x.name} className="grid grid-cols-6">
  //               <div className="a">{x.name}</div>
  //               <div className="a">{x.description}</div>
  //               <div className="a">{x.measurement}</div>
  //               <div className="a">{x.category}</div>
  //               <div className="a">{x.sub_category}</div>
  //               <div className="flex gap-1">
  //                 {x.variation.map((xx) => {
  //                   return (
  //                     <div key={xx} className="p-1 bg-gray-300">
  //                       {xx}
  //                     </div>
  //                   );
  //                 })}
  //               </div>
  //             </div>
  //           );
  //         })}
  //     </div>
  //   </div>
  // );
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/shengyi" element={<Dashboard />} />

        <Route path="/" element={<BasicLayout />}>
          <Route index element={<Home />} />
          <Route path="product/:product_id" element={<Product />} />
          <Route path="shop" element={<ShopPage />}>
            <Route index element={<ShopPage />} />
            <Route path=":filter" element={<ShopPage />} />
          </Route>
          <Route path="catalog" element={<CatalogPage />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="career" element={<CareerPage />} />
          <Route path="checkout" element={<CheckOutPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function BasicLayout() {
  return (
    <div className="flex flex-col min-h-screen text-[0.8rem] md:text-[0.75rem] xl:text-[1em]">
      <Header />
      <Outlet></Outlet>
      <Footer />
    </div>
  );
}
export default App;
