import { useFirebaseApp } from "./useFirebaseApp";
import { v4 as uuidv4 } from "uuid";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import {
  getDatabase,
  ref,
  onValue,
  limitToLast,
  query,
  onChildAdded,
  push,
  equalTo,
  off,
  orderByChild,
  get,
  update,
  set,
  field,
  increment,
} from "firebase/database";
import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";
import { useFireUser } from "./useFireUser";

let db;
let currentEmail;
let user;
const useFireUploadImpl = () => {
  const [state, setState] = useState(null);
  const app = useFirebaseApp();

  useEffect(() => {
    if (!app) return;
    console.log("FIREBASE @ initialize fire upload");
    db = getDatabase();
    // FireCustom();
  }, [app]);

  return state;
};
export const useFireUpload = singletonHook(null, useFireUploadImpl);

export async function UploadSingleFile(path, file, progressCB) {
  return new Promise(function (resolve, reject) {
    // Create a root reference
    try {
      const storage = getStorage();
      const imageRef = storageRef(storage, path);
      const uploadTask = uploadBytesResumable(imageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log("Upload is " + progress + "% done");
          progressCB(Math.trunc(progress) + "%");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        async () => {
          // Upload completed successfully, now we can get the download URL
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(url);
        }
      );
    } catch (error) {
      console.log("error", error);
      reject({ error });
    }
  });
}

export async function UploadMultipleFile(projectID, file) {
  // Create a root reference
  try {
    let url = [];
    const storage = getStorage();
    for (let i = 0; i < file.length; i++) {
      const file_el = file[i];
      const imageRef = storageRef(storage, `${projectID}/${uuidv4()}`);
      const res = await uploadBytes(imageRef, file_el);
      const _url = await getDownloadURL(res.ref);
      url.push(_url);
    }
    return url;
  } catch (error) {
    console.log("error", error);
    return { error };
  }
}
